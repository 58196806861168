.index-container {
    @import url('./modules/banner.less');
    @import url('./modules/recommend.less');
    @import url('./modules/contact.less');
    width: 100%;
    min-width: 1360px;
    @media screen and (max-width: 800px) {
        min-width: auto;
    }
    .license-content {
        width: 100%;
        margin: 0;
        @media screen and (max-width: 800px) {
            overflow: hidden;
        }
        > div {
            // padding: 0;
            @media screen and (max-width: 800px) {
                width: 100%;
            }
        }
    }

    // TODO
    .ant-tabs-tab-btn {
        color: rgba(255, 255, 255, 0.5);
    }
    .ant-tabs-tabpane {
        color: #fff;
    }
}
