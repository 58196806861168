.icon-demo-container {
    background: #1d1d22;
    min-height: 1200px;
    .wrapper {
        width: 840px;
        margin: auto;
        .item {
            display: inline-block;
            text-align: center;
            margin-top: 20px;
            .name {
                font-size: 12px;
                color: #fff;
            }
            .icon-wrap {
                width: 120px;
                height: 120px;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                .anticon {
                    font-size: 60px;
                    transition: font-size 0.2s linear, width 0.2s linear;
                }
                &:hover {
                    .anticon {
                        font-size: 120px;
                    }
                }
            }
        }
    }
}
