.billboards-container {
    color: #fff;
    text-align: center;
    width: 100%;
    padding: 30px;
    padding-top: 26vh;
    .title {
        font-size: 30px;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
    }
    .subtitle {
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 30px;
    }
    .btns {
        display: flex;
        column-gap: 30px;
        justify-content: center;
        .ant-btn {
            width: 200px;
            height: 48px;
            border-radius: 48px;
            span {
                font-size: 16px;
            }
            &:first-child {
            }
        }
    }
    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
        color: #000;
    }
}
