.create-container {
    @import url('./modules/modals.less');
    @import url('./modules/billboards.less');
    .white-color {
        color: #fff;
    }
    .ant-form-item-label > label {
        color: #fff;
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        font-size: 18px;
        .title {
            display: flex;
            align-items: center;
            .star {
                color: rgba(254, 103, 121, 1);
                margin-left: 6px;
            }
        }
        .subtitle {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);
            span {
                color: #fff;
            }
        }
    }
    .ant-input {
        line-height: 2.4;
        background: rgba(0, 0, 0, 0.3);
        border: 1px solid rgba(172, 143, 255, 0.2);
        border-radius: 6px;
        color: #fff;
        font-size: 16px;
    }
    textarea.ant-input {
        height: 140px;
    }

    // ant-select start
    .ant-select {
        color: #fff;
        font-size: 16px;
        line-height: 30px;
    }

    .ant-select-item {
        color: #fff;
        line-height: 30px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 48px;
        border-radius: 6px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: rgba(0, 0, 0, 0.3);
        border: 1px solid rgba(172, 143, 255, 0.1);
    }
    .ant-select-dropdown {
        background-color: #1d1d23;
        border: 1px solid rgba(172, 143, 255, 0.1);
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: rgba(255, 255, 255, 0.06);
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: rgba(255, 255, 255, 0.2);
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 46px;
    }
    .ant-select-arrow {
        color: rgba(255, 255, 255, 0.5);
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid rgba(172, 143, 255, 0.2);
        -webkit-box-shadow: 0 0 0 2px rgb(255 255 255 / 20%);
        box-shadow: 0 0 0 2px rgb(255 255 255 / 20%);
        border-right-width: 1px;
        outline: 0;
    }
    .ant-empty-small {
        color: #fff;
    }
    // ant-select end

    .ant-spin-nested-loading > div > .ant-spin {
        max-height: 400px;
        position: absolute;
        max-height: 100%;
    }
    .ant-spin-container {
        &:after {
            background: none;
        }
    }
    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
        .ant-select-selector {
        background: none;
    }
    .ant-upload-list {
        color: #fff;
    }
    .ant-upload-list-picture .ant-upload-list-item-error,
    .ant-upload-list-picture-card .ant-upload-list-item-error {
        border-radius: 8px;
    }
    .ant-upload-list-picture-card-container {
        width: 102px;
        height: 102px;
    }
    .ant-upload.ant-upload-select-picture-card {
        background-color: transparent;
        border: 1px dashed rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        &:hover {
            background: rgba(255, 255, 255, 0.05);
            border: 1px dashed rgba(255, 255, 255, 0.5);
            border-radius: 8px;
        }
        .micon {
            font-size: 20px;
            & + div {
                margin: 0 !important;
            }
        }
    }
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
        background: none;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
        background: #1d1d23;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 100%;
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
        border-radius: 8px;
    }
    // m-cover start
    .m-cover {
        .ant-upload-select-picture-card {
            width: 670px;
            height: 200px;
            .micon {
                font-size: 40px;
            }
        }
        .ant-upload-list-picture-card-container {
            width: 670px;
            height: 200px;
        }
    }
    // m-cover start
}
.create-spinning {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background: rgba(0, 0, 0, 0.7);
    .ant-spin-spinning {
        position: fixed !important;
    }
    .ant-spin-blur {
        opacity: 0.1;
    }
    .ant-spin-text {
        text-shadow: none !important;
    }
}
.create-container {
    .primary-btn {
        height: 40px;
        background: rgba(59, 130, 246, 0.5);
        border: none;
        border-radius: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
            background: #3b82f6;
        }
    }
    .secondary-btn {
        height: 40px;
        background: rgba(98, 59, 209, 0.5);
        border: none;
        border-radius: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
            background: #623bd1;
        }
    }
    .c-header {
        padding: 60px 0px 20px 0;
        border-bottom: 1px solid rgba(172, 143, 255, 0.2);
        width: calc(100% - 80px);
        margin: auto;
        margin-bottom: 10px;
        text-align: left;
        .title {
            font-weight: 700;
            font-size: 36px;
            line-height: 36px;
            color: #ffffff;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .btns {
                display: flex;
                column-gap: 20px;
            }
        }
        .tips {
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: rgba(255, 255, 255, 0.5);
            padding: 0;
            .star {
                color: #fe6779;
                margin-right: 4px;
            }
        }
    }
    .form-content {
        width: 730px;
        margin: auto;
        padding: 30px;
    }
    .supply-limit-inline {
        margin-bottom: 0;
        .ant-form-item-control-input-content {
            display: flex;
            justify-content: space-between;
            .ant-form-item {
                width: 49%;
            }
        }
    }
    .form-license-nft-item {
        .wrap {
            display: flex;
            flex-wrap: wrap;
            .nft-item {
                width: 80px;
                height: 80px;
                border-radius: 8px;
                display: inline-block;
                margin-right: 10px;
                // margin-bottom: 10px;
                cursor: pointer;
                position: relative;
                border-radius: 8px;
                overflow: hidden;
                img {
                    object-fit: contain;
                }
                .default-img {
                    width: 80px;
                    height: 80px;
                    font-size: 80px;
                    opacity: 0.3;
                    border: none;
                    &:hover {
                        border: none;
                    }
                }
                .status-0 {
                    position: absolute;
                    background: none;
                    border: none;
                    width: 20px;
                    height: 20px;
                    font-size: 20px;
                    bottom: 6px;
                    right: 6px;
                    &:hover {
                        border: none;
                    }
                }
                .status-1 {
                    position: absolute;
                    background: none;
                    border: none;
                    width: 20px;
                    height: 20px;
                    font-size: 20px;
                    bottom: 6px;
                    right: 6px;
                    &:hover {
                        border: none;
                    }
                }
                .status-2 {
                    position: absolute;
                    background: none;
                    border: none;
                    width: 20px;
                    height: 20px;
                    font-size: 20px;
                    bottom: 6px;
                    right: 6px;
                    &:hover {
                        border: none;
                    }
                }
                .delete-btn {
                    position: absolute;
                    background: none;
                    border: none;
                    width: 28px;
                    height: 28px;
                    background: rgba(0, 0, 0, 1);
                    opacity: 0.4;
                    font-size: 12px;
                    bottom: 6px;
                    border-radius: 100%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    &:hover {
                        opacity: 0.9;
                        background: rgba(0, 0, 0, 1);
                        border: none;
                        border-radius: 100%;
                    }
                }
            }
            .micon {
                width: 80px;
                height: 80px;
                border: 1px dashed rgba(255, 255, 255, 0.5);
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                // margin-bottom: 10px;
                cursor: pointer;
                &:hover {
                    background: rgba(255, 255, 255, 0.05);
                    border: 1px dashed rgba(255, 255, 255, 0.5);
                    border-radius: 8px;
                }
            }
        }
    }
    .special-item {
        border-bottom: 1px solid rgba(172, 143, 255, 0.1);
        .special-item-label {
            display: flex;
            justify-content: space-between;
            width: 670px;
            height: 58px;
            padding-bottom: 10px;
            .left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .row1 {
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    line-height: 18px;
                    .micon {
                        svg {
                            font-size: 18px;
                            margin-right: 8px;
                        }
                    }
                }
                .row2 {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: rgba(255, 255, 255, 0.5);
                    padding-left: 25px;
                }
            }
            .right {
                .micon {
                    cursor: pointer;
                    font-size: 45px;
                    line-height: 1;
                    opacity: 0.5;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
        .ant-form-item-control {
            // display: none;
            .ant-form-item-control-input {
                min-height: 0;
            }
        }
        &.form-properties-item {
            .list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                justify-content: flex-start;
                column-gap: 34px;
                .item {
                    width: 200px;
                    height: 72px;
                    background: rgba(172, 143, 255, 0.08);
                    border-radius: 10px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 20px;
                    .type {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 14px;
                        color: rgba(255, 255, 255, 0.5);
                        margin-bottom: 6px;
                    }
                    .name {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 16px;
                        color: #ffffff;
                        width: 200px;
                        span {
                            color: #ffffff;
                            display: inline-block;
                            width: 80%;
                            // 超长 ...
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
        &.form-levels-item {
            .list {
                .item {
                    padding: 14px 20px;
                    width: 100%;
                    height: 72px;
                    background: rgba(172, 143, 255, 0.08);
                    border-radius: 10px;
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    &:last-child {
                        // margin-bottom: 0;
                    }
                    .wrap {
                        width: 100%;
                        .row1 {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 10px;
                            .left {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 16px;
                                color: rgba(255, 255, 255, 0.5);
                            }
                            .right {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 16px;
                                text-align: right;
                                color: #ffffff;
                            }
                        }
                        .row2 {
                            width: 100%;
                            height: 12px;
                            background: rgba(0, 0, 0, 0.3);
                            border-radius: 6px;
                            position: relative;

                            .slider {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 0;
                                height: 100%;
                                background: #3b5bd1;
                                border-radius: 6px;
                            }
                        }
                    }
                }
            }
        }
        &.form-stats-item {
            .list {
                .item {
                    padding: 0 20px;
                    width: 100%;
                    height: 36px;
                    background: rgba(172, 143, 255, 0.08);
                    border-radius: 10px;
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    .wrap {
                        width: 100%;
                        .row {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .left {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 16px;
                                color: rgba(255, 255, 255, 0.5);
                            }
                            .right {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 16px;
                                text-align: right;
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }

    .footer {
        text-align: center;
        margin-top: 70px;
        .create-btn {
            width: 400px;
        }
        .edit-btns {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 40px;
            .cancel-btn {
                width: 180px;
                height: 50px;
                background: none;
                color: #fff;
                border-radius: 8px;
                font-size: 18px;
                border: 1px solid rgba(172, 143, 255, 0.3);
                &:hover {
                    border: 1px solid rgba(172, 143, 255, 1);
                }
            }
            .save-btn {
                width: 180px;
                height: 50px;
                border-radius: 8px;
                font-size: 18px;
            }
        }
    }
    .goto-opensea {
        position: relative;
        // padding-left: 3px;
        &::after {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: -2px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }
    }
}

.preview-modal {
    .ant-modal-content {
        background-color: var(--backgroundColor);
    }
    .ant-modal-close-x {
        color: #fff;
    }
    .ant-modal-header {
        background: var(--backgroundColor);
        color: #fff;
        border-bottom: 1px solid rgba(172, 143, 255, 0.2);
        .ant-modal-title {
            color: #fff;
        }
    }
}
