.top-mobile-container-new {
    width: 100%;
    color: #fff;
    display: none;
    padding-top: 20px;
    padding-left: 20px;
    @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
    }
    .avatar_address {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 40px;
        align-items: center;
        .avatar {
            width: 40px;
            height: 40px;
            background: rgba(196, 196, 196, 0.5);
            border-radius: 20px;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
        }
        .address {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            height: 40px;
            line-height: 40px;
            margin-left: 10px;
        }
    }
    .enter {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        img {
            width: 20px;
            height: 20px;
            border-radius: 20px;
            margin-right: 20px;
        }
    }
}
