.tabs-card-container-new {
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media screen and (max-width: 800px) {
        display: none;
    }
    .filter {
        img {
            width: 20px;
            height: 20px;
        }
    }
    .tabs-filter {
        .title {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: #3b82f6;
            line-height: 16px;
            padding: 12px 0;
            padding-left: 40px;
            background: #1d1d23;
            cursor: pointer;
            z-index: 30;
        }
        .filter_filter {
            width: 300px;
            height: 90vh;
            overflow-y: scroll;
            overflow-x: hidden;
            background: rgba(172, 143, 255, 0.08);
            color: #fff;
            .checkout {
                padding: 10px 20px;
                display: flex;
                flex-direction: column;
                .checkout_outer {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    word-break: break-all;
                    .pic6 {
                        width: 30px;
                        height: 30px;
                        margin: 0 5px;
                    }
                }
            }
            &::-webkit-scrollbar {
                width: 3px;
                height: 2px;
                box-shadow: inset 0 0 6px transparent;
                -webkit-border-radius: 10px;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 0 transparent;
                background: rgba(228, 228, 228, 0.2);
            }

            &::-webkit-scrollbar-thumb {
                background:rgba(255, 255, 255, 0.2);
                height: 2px;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-corner {
                display: none;
                height: 0;
                width: 0;
            }
        }
    }
}
.select_pic5 {
    width: 16px;
    height: 16px;
    margin-top: 5px;
    margin-right: 50px;
    margin-left: 40px;
    color: #fff;
    cursor: pointer;
    position: relative;
    img {
        position: absolute;
        z-index: 3;
    }
    .select_blank1 {
        position: absolute;
        width: 105px;
        height: 20px;
        left: -40px;
        background-color: #1d1d23;
        top: 6px;
        z-index: 0;
    }
}
.select_title {
    color: #fff;
    position: relative;
    margin-right: 60px;
    input {
        &::placeholder {
            color: rgba(255, 255, 255, 0.3);
        }
    }
    .select_blank2 {
        position: absolute;
        right: -60px;
        bottom: -5px;
        width: 380px;
        height: 4px;
        background-color: #1d1d23;
    }
}
.select_input {
    width: 300px;
    height: 30px;
    background: rgba(172, 143, 255, 0.08);
    border-radius: 10px;
    border: none;
    color: rgba(255, 255, 255, 0.3);
}
.site-custom-tab-bar {
    z-index: 1;
    background: #1d1d23;
}
.ant-skeleton-title {
    width: 80% !important;
}
