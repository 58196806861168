.header-container {
    @import url('./modules/search.less');
    z-index: 15;
    width: 100%;

    // @media screen and (min-width: 800px) {
    //     padding-left: calc(100vw - 100%);
    // }

    .wrapper {
        height: 80px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 15;
        border-bottom: 1px solid #3e3355;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px;
        @media screen and (max-width: 800px) {
            padding: 0 14px;
            border-bottom: none;
        }
        .left {
            flex: 1;
            .logo {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                opacity: 0.8;
                cursor: pointer;
                .anticon {
                    width: 205px;
                    height: 59px;
                    @media screen and (max-width: 800px) {
                        width: 150px;
                    }
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .middle {
            @media screen and (max-width: 800px) {
                display: none;
            }
        }
        .right {
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            &.m-right {
                display: none;
            }
            @media screen and (max-width: 800px) {
                display: none;
                &.m-right {
                    display: flex;
                    align-items: center;
                    .menu-icon {
                        margin-right: 14px;
                        svg {
                            font-size: 20px;
                        }
                    }
                    .wallet-status {
                        width: 8px;
                        height: 8px;
                        border-radius: 8px;
                        position: relative;
                        top: -2px;
                        &.on-line {
                            background: #8aeda0;
                        }
                        &.off-line {
                            background: #fe6779;
                        }
                    }
                }
            }

            .list {
                height: 100%;
                text-align: right;
                position: relative;
                display: flex;
                align-items: center;
                right: -4px;
                @media screen and (max-width: 800px) {
                    display: none;
                }
                .item {
                    cursor: pointer;
                    position: relative;
                    width: 60px;
                    height: 100%;
                    justify-content: center;
                    display: inline-block;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    &.activate {
                        .title {
                            opacity: 0;
                        }
                        .micon {
                            font-size: 24px;
                            position: relative;
                            svg {
                                opacity: 1;
                            }
                            &:after {
                                content: '';
                                position: absolute;
                                width: 11px;
                                height: 3px;
                                left: 7px;
                                border-radius: 3px;
                                background: #fff;
                                top: 28px;
                            }
                        }
                    }

                    .micon {
                        font-size: 24px;
                        position: relative;
                    }

                    .title {
                        display: none;
                        position: absolute;
                        color: #fff;
                        top: 28px;
                        opacity: 0.5;
                    }
                    svg {
                        color: #fff;
                        opacity: 0.5;
                    }
                    &:hover {
                        .title {
                            display: block;
                        }
                        svg {
                            opacity: 0.9;
                        }
                    }
                    &.user-photo-item {
                        // display: flex;
                        // align-items: center;
                        .user-photo {
                            width: 32px;
                            height: 32px;
                            border-radius: 100%;
                        }
                    }
                }
            }
        }
    }
    .mobile-menu,
    .mask {
        display: none;
        @media screen and (max-width: 800px) {
            &.mask {
                opacity: 0;
                z-index: 16;
                visibility: hidden;
                display: block;
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                // transition: opacity 0.1s, visibility 0s linear 0.1s;
                &.is-open {
                    opacity: 1;
                    visibility: visible;
                    // transition: opacity 0.3s ease, visibility 0s;

                    @supports not (backdrop-filter: blur(4px)) {
                        background: hsla(0, 0%, 7%, 0.6);
                    }

                    @supports (backdrop-filter: blur(4px)) or (-webkit-backdrop-filter: blur(4px)) {
                        background-color: hsla(0, 0%, 7%, 0.6);
                        -webkit-backdrop-filter: blur(6px);
                        backdrop-filter: blur(6px);
                    }
                }
            }
            &.mobile-menu.is-open {
                display: block;
                position: fixed;
                z-index: 900;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                height: 100%;
                overflow: auto;
                padding-bottom: 30px;
                .wrap {
                    color: #fff;
                    position: relative;
                    .close-btn {
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        right: 30px;
                        top: 22px;
                        svg {
                            font-size: 16px;
                        }
                    }
                    .menu-list {
                        text-align: right;
                        padding-top: 70px;
                        .item {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            padding-right: 32px;
                            height: 50px;
                            line-height: 50px;
                            .wallet-status {
                                width: 8px;
                                height: 8px;
                                border-radius: 8px;
                                margin-right: 4px;
                                &.on-line {
                                    background: #8aeda0;
                                }
                                &.off-line {
                                    background: #fe6779;
                                }
                            }
                            .title {
                                font-weight: 400;
                                font-size: 18px;
                                color: #ffffff;
                            }
                            .micon {
                                margin-left: 12px;
                                svg {
                                    font-size: 26px;
                                }
                            }
                            .user-photo {
                                width: 28px;
                                height: 28px;
                                border-radius: 100%;
                                margin-left: 9px;
                            }
                            .address {
                                font-weight: 400;
                                font-size: 18px;
                                color: #ffffff;
                            }
                        }
                    }
                    .contact-container {
                        padding-top: 30px;
                        .contact-content {
                            display: flex;
                            justify-content: flex-end;
                            .item {
                                margin-right: 30px;
                                cursor: pointer;
                                &:hover {
                                    opacity: 0.3;
                                }
                                svg {
                                    font-size: 44px;
                                }
                            }
                        }
                    }
                    .search-content {
                        margin-top: 50px;
                        width: calc(100% - 30px - 30px);
                        margin-left: 30px;
                        border: 1px solid rgba(172, 143, 255, 0.2);
                    }
                }
            }
        }
    }
    .shim {
        display: none;
        width: 100%;
        height: 80px;
    }
    &:not(.index-header) {
        .shim {
            display: block;
        }
    }
    .modal-userinfo {
        @media screen and (max-width: 800px) {
            width: 335px !important;
        }
    }
    .modal-userinfo-content {
        padding-top: 30px;
        padding-bottom: 20px;
        .row1 {
            display: flex;
            justify-content: center;
            align-items: center;
            .left {
                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                }
            }
            .right {
                padding-left: 10px;
                .item-row1 {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    color: rgba(255, 255, 255, 0.5);
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    position: relative;
                    .on-line {
                        width: 6px;
                        height: 6px;
                        background: #75c473;
                        border-radius: 100%;
                        margin-left: 5px;
                        position: relative;
                        top: 1px;
                    }
                }
                .item-row2 {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 16px;
                    text-align: left;
                    color: #ffffff;
                    margin-top: 3px;
                }
            }
        }
        .row2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 34px;
            margin-bottom: 60px;
            .label {
                font-weight: 300;
                font-size: 14px;
                line-height: 14px;
                color: rgba(255, 255, 255, 0.5);
                margin-bottom: 4px;
            }
            .value {
                font-weight: 500;
                font-size: 24px;
                line-height: 24px;
                color: #ffffff;
            }
        }
        .row3 {
            display: flex;
            justify-content: center;
            gap: 30px;
            @media screen and (max-width: 800px) {
                flex-direction: column;
                align-items: center;
                gap: 20px;
            }
            .ant-btn {
                width: 130px;
                height: 40px;
                background: rgba(172, 143, 255, 0.08);
                border-radius: 10px;
                border: none;
                color: #fff;
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                text-align: center;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                @media screen and (max-width: 800px) {
                    // width: 110px;
                    // height: 30px;
                    width: 275px;
                }
                &:hover {
                    background: rgba(172, 143, 255, 0.3);
                }
                .micon {
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    &[name='icon58'] {
                        position: relative;
                        top: 2px;
                    }
                    &[name='icon59'] {
                        position: relative;
                        top: 1px;
                    }
                }
            }
        }
    }
}
