.tabs-card-mobile-container{
    display: none;
    @media screen and (max-width: 800px) {
        width:100%;
        color:#fff;
        display: flex;
        flex-direction: row;
        justify-content:center;
    }
}
.search{
    display: none;
    @media screen and (max-width: 800px) {
        display: flex;
        align-items: center;
        margin-top:30px;
        .select_input{
            width:80%;
            height:50px;
            margin-left:10%;
        }
    }
    
}
.select_pic55{
    width:16px;
    height:16px;
    margin-left:20px;
    margin-right:20px;
    color:#fff;
    cursor:pointer;
}
.drawer_title{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .img{
      width:16px;
      height:16px;
    }
    .word{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #3B82F6;
        margin-left:11px;
    }
}
.drawer_close{
    width:20px;
    height:20px;
    color: #3B82F6;
}


.ant-drawer-content{
    background: #23262F;
    color:#fff;
}
.ant-drawer-header{
    border-bottom: 1px solid rgba(172, 143, 255, 0.2);
}
.ant-divider-horizontal{
    border-top: 1px solid rgba(172, 143, 255, 0.2);
    margin: 8px 0;
    background: 0 0;
}
.ant-checkbox-wrapper{
    color: rgba(255, 255, 255, 0.5);
    &:hover{
        color: rgba(255, 255, 255, 1);
    }
}
.ant-checkbox-wrapper{
    display: flex;
    align-items: center;
}
.ant-checkbox-inner {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.ant-checkbox-checked .ant-checkbox-inner {
    background: #3B82F6;
    border-color:#3B82F6; ;
    border-radius: 4px;
}
.ant-checkbox-checked + span{
    color: rgba(255, 255, 255, 1);
}
.ant-drawer-close{
    display: none!important;
}
.filter_filter{
    color:#fff;
    .checkout{
     padding:10px 20px;
     display: flex;
     flex-direction: column;
     .checkout_outer{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .pic6{
            width:30px;
            height:30px;
            margin:0 5px;
        }
     }
    }
}
