.index-container {
  width: 100%;
  min-width: 1360px;
}
.index-container .banner-container {
  height: 1560px;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 800px) {
  .index-container .banner-container {
    height: calc(1470px + 44vw);
  }
}
.index-container .banner-container .orb-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1600px;
  pointer-events: none;
  z-index: 11;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
}
@media screen and (max-width: 800px) {
  .index-container .banner-container .orb-canvas {
    height: calc(1340px + 44vw);
  }
}
.index-container .banner-container .img-wrap {
  width: 1360px;
  position: relative;
  margin: auto;
  height: 100%;
}
@media screen and (max-width: 800px) {
  .index-container .banner-container .img-wrap {
    width: 100%;
    overflow: hidden;
  }
}
.index-container .banner-container .img-wrap .item-img {
  position: absolute;
}
.index-container .banner-container .img-wrap .item-img.banner-planet {
  left: 50%;
  transform: translateX(-50%);
  width: 800px;
  height: 800px;
  top: 100px;
  z-index: 2;
}
@media screen and (max-width: 800px) {
  .index-container .banner-container .img-wrap .item-img.banner-planet {
    width: 600px;
    height: 600px;
    top: 440px;
    left: 50vw;
  }
}
.index-container .banner-container .img-wrap .item-img.banner-astronaut {
  width: 300px;
  height: 356px;
  z-index: 5;
  top: 220px;
  left: 100px;
}
@media screen and (max-width: 800px) {
  .index-container .banner-container .img-wrap .item-img.banner-astronaut {
    top: 70px;
    left: initial;
    right: -60px;
    width: 240px;
    height: 284px;
  }
}
.index-container .banner-container .img-wrap .item-img.banner-ascard {
  width: 750px;
  height: 793px;
  z-index: 1;
  top: 240px;
}
@media screen and (max-width: 800px) {
  .index-container .banner-container .img-wrap .item-img.banner-ascard {
    width: 500px;
    height: 530px;
    top: -70px;
    left: -120px;
  }
}
.index-container .banner-container .img-wrap .item-img.banner-rocket {
  right: 30px;
  top: 100px;
  width: 620px;
  height: 478px;
  z-index: 5;
}
@media screen and (max-width: 800px) {
  .index-container .banner-container .img-wrap .item-img.banner-rocket {
    width: 520px;
    height: 398px;
    top: 350px;
  }
}
.index-container .banner-container .banner-content-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.index-container .banner-container .banner-content-box .row {
  text-align: center;
}
.index-container .banner-container .banner-content-box .row.row1 {
  padding-top: 340px;
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 96px;
  text-align: center;
  color: #fff;
}
@media screen and (max-width: 800px) {
  .index-container .banner-container .banner-content-box .row.row1 {
    padding-top: 620px;
    font-size: 0px;
    line-height: 56px;
  }
}
.index-container .banner-container .banner-content-box .row.row2 {
  padding-top: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  max-width: 416px;
  margin: auto;
  color: #fff;
  opacity: 0.8;
}
@media screen and (max-width: 800px) {
  .index-container .banner-container .banner-content-box .row.row2 {
    font-size: 32px;
    padding-top: 12px;
  }
}
.index-container .banner-container .banner-content-box .row.row3 {
  padding-top: 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 800px) {
  .index-container .banner-container .banner-content-box .row.row3 {
    padding-top: 20px;
  }
}
.index-container .banner-container .banner-content-box .row.row3 p {
  line-height: 1.4;
  margin: 0;
}
.index-container .banner-container .banner-content-box .row.row4 {
  padding-top: 40px;
}
.index-container .banner-container .banner-content-box .row.row4 .wrap {
  display: flex;
  justify-content: center;
}
.index-container .banner-container .banner-content-box .row.row4 .wrap .btn {
  background: none;
  border: none;
  width: 120px;
  height: 50px;
  padding: 0;
  position: relative;
  cursor: pointer;
  margin: 0 2px;
}
.index-container .banner-container .banner-content-box .row.row4 .wrap .btn .micon {
  width: 150px;
  height: 50px;
  position: absolute;
  left: -15px;
  top: 0;
}
.index-container .banner-container .banner-content-box .row.row4 .wrap .btn .micon[name='icon17'] svg {
  width: 100%;
  height: 100%;
}
.index-container .banner-container .banner-content-box .row.row4 .wrap .btn .micon[name='icon17'] svg path[data-name='path1'] {
  fill-opacity: 0.3;
}
.index-container .banner-container .banner-content-box .row.row4 .wrap .btn .micon[name='icon18'] svg {
  width: 100%;
  height: 100%;
}
.index-container .banner-container .banner-content-box .row.row4 .wrap .btn .micon[name='icon18'] svg path[data-name='path1'] {
  opacity: 0.6;
}
.index-container .banner-container .banner-content-box .row.row4 .wrap .btn:hover .micon[name='icon17'] svg path[data-name='path1'] {
  fill-opacity: 0.5;
}
.index-container .banner-container .banner-content-box .row.row4 .wrap .btn:hover .micon[name='icon18'] svg path[data-name='path1'] {
  opacity: 0.9;
}
.index-container .banner-container .banner-content-box .row.row4 .wrap .btn .name {
  position: absolute;
  font-weight: 700;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.9);
  width: calc(100% - 15px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 0;
  left: 0;
}
.index-container .banner-container .banner-content-box .row.row4 .wrap .btn:last-child .name {
  left: initial;
  right: 0;
}
.index-container .banner-container .banner-content-box .row.row5 {
  padding-top: 200px;
}
@media screen and (max-width: 800px) {
  .index-container .banner-container .banner-content-box .row.row5 {
    position: absolute;
    top: 0;
    padding-top: 100px;
  }
}
.index-container .banner-container .banner-content-box .row.row5 .wrap {
  display: flex;
  position: relative;
  justify-content: center;
}
@media screen and (max-width: 800px) {
  .index-container .banner-container .banner-content-box .row.row5 .wrap {
    flex-direction: column;
  }
}
.index-container .banner-container .banner-content-box .row.row5 .wrap .box {
  position: relative;
  min-width: 260px;
  padding-left: 50px;
  padding-right: 20px;
  opacity: 0.8;
}
@media screen and (max-width: 800px) {
  .index-container .banner-container .banner-content-box .row.row5 .wrap .box {
    padding-bottom: 30px;
    padding-left: 34px;
  }
}
.index-container .banner-container .banner-content-box .row.row5 .wrap .box.box1 {
  padding-left: 30px;
}
.index-container .banner-container .banner-content-box .row.row5 .wrap .box:after {
  content: '';
  height: 100%;
  top: 0%;
  width: 1px;
  border-left: 1px solid rgba(217, 217, 217, 0.5);
  right: 0;
  position: absolute;
}
@media screen and (max-width: 800px) {
  .index-container .banner-container .banner-content-box .row.row5 .wrap .box:after {
    border: none;
  }
}
.index-container .banner-container .banner-content-box .row.row5 .wrap .box:last-child:after {
  border: none;
}
.index-container .banner-container .banner-content-box .row.row5 .wrap .box .text {
  text-align: left;
}
.index-container .banner-container .banner-content-box .row.row5 .wrap .box .text.text1 {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.5);
}
.index-container .banner-container .banner-content-box .row.row5 .wrap .box .text.text1 [name='icon14'] {
  opacity: 0.8;
  margin-left: 6px;
}
.index-container .banner-container .banner-content-box .row.row5 .wrap .box .text.text2 {
  padding-top: 20px;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  color: #ffffff;
}
@media screen and (max-width: 800px) {
  .index-container .banner-container .banner-content-box .row.row5 .wrap .box .text.text2 {
    padding-top: 3px;
  }
}
.index-container .banner-container .banner-content-box .row.row5 .wrap .box .text.text3 {
  padding-top: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 800px) {
  .index-container .banner-container .banner-content-box .row.row5 .wrap .box .text.text3 {
    padding-top: 0px;
  }
}
.index-container .banner-container .banner-content-box .row.row5 .wrap .box.box3 .text.text2 {
  background: linear-gradient(90deg, #f800fd 1.67%, #7e00fd 50.03%, #4c9cf9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.index-container .primary-btn {
  height: 40px;
  background: rgba(59, 130, 246, 0.5);
  border: none;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  cursor: pointer;
}
.index-container .primary-btn:hover,
.index-container .primary-btn:active,
.index-container .primary-btn:focus {
  background: #3b82f6;
}
.index-container .secondary-btn {
  height: 40px;
  background: rgba(98, 59, 209, 0.5);
  border: none;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  cursor: pointer;
}
.index-container .secondary-btn:hover,
.index-container .secondary-btn:active,
.index-container .secondary-btn:focus {
  background: #623bd1;
}
.index-container .recommend-container {
  position: absolute;
  top: 1050px;
  width: 100%;
  z-index: 12;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container {
    top: calc(1090px - 8vw);
  }
}
.index-container .recommend-container .wrapper {
  width: 990px;
  margin: 0 auto;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper {
    width: 100%;
  }
}
.index-container .recommend-container .wrapper .slick-dots {
  width: 232px;
  left: 0;
  margin: 0;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .slick-dots {
    position: absolute;
    top: calc(44vw + 210px);
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 3px;
  }
}
.index-container .recommend-container .wrapper .slick-dots li {
  width: 60px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}
.index-container .recommend-container .wrapper .slick-dots li.slick-active {
  background: rgba(255, 255, 255, 0.5);
}
.index-container .recommend-container .wrapper .carousel-item-wrap {
  display: flex;
  justify-content: space-between;
  width: 990px;
  padding: 0 10px;
  margin: auto;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap {
    flex-wrap: wrap;
    width: 100%;
    padding: 0 4vw;
  }
}
.index-container .recommend-container .wrapper .carousel-item-wrap .m-recommend-title {
  display: none;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap .m-recommend-title {
    display: block;
    width: 100%;
    order: 0;
    font-style: normal;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 30px;
  }
  .index-container .recommend-container .wrapper .carousel-item-wrap .m-recommend-title span:last-child {
    font-size: 24px;
    margin-left: 10px;
    font-weight: 600;
  }
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item {
  width: 300px;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap .item {
    width: 44vw;
  }
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate {
  width: 232px;
  margin-right: 40px;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap .item.operate {
    order: 3;
    width: 100%;
    margin-right: 0;
    position: relative;
  }
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row1,
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row2 {
  font-weight: 300;
  font-size: 36px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 10px;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row1,
  .index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row2 {
    display: none;
  }
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row2 {
  margin-bottom: 30px;
  font-weight: 500;
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row3,
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row4 {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row3,
  .index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row4 {
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 14px;
  }
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row3 .label,
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row4 .label {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
  margin-right: 10px;
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row3 .value,
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row4 .value {
  display: flex;
  align-items: center;
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row3 .value .amount,
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row4 .value .amount {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row3 {
  margin-bottom: 10px;
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row4 {
  margin-bottom: 40px;
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row5 {
  margin-bottom: 30px;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row5 {
    text-align: center;
    margin-top: 60px;
  }
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row5 [type='button'] {
  width: 100%;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row5 [type='button'] {
    height: 50px;
    width: 231px;
    margin: auto;
  }
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row6 {
  margin-bottom: 50px;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row6 {
    text-align: center;
  }
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row6 [type='button'] {
  width: 100%;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row6 [type='button'] {
    height: 50px;
    width: 231px;
    margin: auto;
  }
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row7 {
  display: flex;
  justify-content: space-between;
  display: none;
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row7 .p-item {
  width: 60px;
  height: 4px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
.index-container .recommend-container .wrapper .carousel-item-wrap .item.operate .row7 .p-item:active {
  background: rgba(255, 255, 255, 0.5);
}
.index-container .recommend-container .wrapper .carousel-item-wrap .nft-item {
  cursor: pointer;
}
.index-container .recommend-container .wrapper .carousel-item-wrap .nft-item .item-wrap {
  border-radius: 6px;
  overflow: hidden;
}
.index-container .recommend-container .wrapper .carousel-item-wrap .nft-item .item-wrap .cover {
  width: 300px;
  height: 300px;
  object-fit: cover;
  position: relative;
  background: rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap .nft-item .item-wrap .cover {
    width: 44vw;
    height: 44vw;
  }
}
.index-container .recommend-container .wrapper .carousel-item-wrap .nft-item .item-wrap .cover img {
  object-fit: contain;
}
.index-container .recommend-container .wrapper .carousel-item-wrap .nft-item .item-wrap .cover .icon {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap .nft-item .item-wrap .cover .icon {
    bottom: 10px;
    right: 10px;
  }
}
.index-container .recommend-container .wrapper .carousel-item-wrap .nft-item .item-wrap .cover .icon svg {
  font-size: 40px;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap .nft-item .item-wrap .cover .icon svg {
    font-size: 30px;
  }
}
.index-container .recommend-container .wrapper .carousel-item-wrap .nft-item .item-wrap .info {
  background: rgba(172, 143, 255, 0.08);
  padding: 15px 20px;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap .nft-item .item-wrap .info {
    padding: 8px 12px;
  }
}
.index-container .recommend-container .wrapper .carousel-item-wrap .nft-item .item-wrap .info .title {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 12px;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap .nft-item .item-wrap .info .title {
    font-size: 12px;
  }
}
.index-container .recommend-container .wrapper .carousel-item-wrap .nft-item .item-wrap .info .subtitle {
  display: flex;
  align-items: center;
  height: 42px;
}
.index-container .recommend-container .wrapper .carousel-item-wrap .nft-item .item-wrap .info .subtitle .text {
  margin-right: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}
@media screen and (max-width: 800px) {
  .index-container .recommend-container .wrapper .carousel-item-wrap .nft-item .item-wrap .info .subtitle .text {
    font-size: 13px;
  }
}
.index-container .contact-container {
  padding-top: 120px;
}
@media screen and (max-width: 800px) {
  .index-container .contact-container {
    padding-top: 40px;
  }
}
.index-container .contact-container .contact-content {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 40px;
}
.index-container .contact-container .contact-content .item {
  margin-right: 60px;
  cursor: pointer;
}
.index-container .contact-container .contact-content .item:hover {
  opacity: 0.3;
}
.index-container .contact-container .contact-content .item svg {
  font-size: 34px;
}
@media screen and (max-width: 800px) {
  .index-container {
    min-width: auto;
  }
}
.index-container .license-content {
  width: 100%;
  margin: 0;
}
@media screen and (max-width: 800px) {
  .index-container .license-content {
    overflow: hidden;
  }
}
@media screen and (max-width: 800px) {
  .index-container .license-content > div {
    width: 100%;
  }
}
.index-container .ant-tabs-tab-btn {
  color: rgba(255, 255, 255, 0.5);
}
.index-container .ant-tabs-tabpane {
  color: #fff;
}
.icon-demo-container {
  background: #1d1d22;
  min-height: 1200px;
}
.icon-demo-container .wrapper {
  width: 840px;
  margin: auto;
}
.icon-demo-container .wrapper .item {
  display: inline-block;
  text-align: center;
  margin-top: 20px;
}
.icon-demo-container .wrapper .item .name {
  font-size: 12px;
  color: #fff;
}
.icon-demo-container .wrapper .item .icon-wrap {
  width: 120px;
  height: 120px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.icon-demo-container .wrapper .item .icon-wrap .anticon {
  font-size: 60px;
  transition: font-size 0.2s linear, width 0.2s linear;
}
.icon-demo-container .wrapper .item .icon-wrap:hover .anticon {
  font-size: 120px;
}
.header-container {
  z-index: 15;
  width: 100%;
}
.header-container .search-content {
  width: 465px;
  height: 40px;
  border-radius: 40px;
  background: rgba(172, 143, 255, 0.08);
  position: relative;
}
.header-container .search-content .input {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 18px;
  padding: 10px 20px;
  border: none;
  background: none;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 16px;
}
.header-container .search-content .input:focus,
.header-container .search-content .input:active,
.header-container .search-content .input:focus-visible {
  outline: none;
  border: none;
}
.header-container .search-content .icon36 {
  position: absolute;
  top: 9px;
  right: 14px;
  font-size: 20px;
  opacity: 0.5;
  cursor: pointer;
}
.header-container .search-content .icon36:hover {
  opacity: 1;
}
.header-container .search-content .allow-clear {
  position: absolute;
  right: 38px;
  top: 7px;
  cursor: pointer;
  opacity: 0.7;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-container .search-content .allow-clear svg {
  font-size: 12px;
}
.header-container .search-content .allow-clear:hover {
  opacity: 1;
}
.header-container .wrapper {
  height: 80px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 15;
  border-bottom: 1px solid #3e3355;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}
@media screen and (max-width: 800px) {
  .header-container .wrapper {
    padding: 0 14px;
    border-bottom: none;
  }
}
.header-container .wrapper .left {
  flex: 1;
}
.header-container .wrapper .left .logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.8;
  cursor: pointer;
}
.header-container .wrapper .left .logo .anticon {
  width: 205px;
  height: 59px;
}
@media screen and (max-width: 800px) {
  .header-container .wrapper .left .logo .anticon {
    width: 150px;
  }
}
.header-container .wrapper .left .logo .anticon svg {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 800px) {
  .header-container .wrapper .middle {
    display: none;
  }
}
.header-container .wrapper .right {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.header-container .wrapper .right.m-right {
  display: none;
}
@media screen and (max-width: 800px) {
  .header-container .wrapper .right {
    display: none;
  }
  .header-container .wrapper .right.m-right {
    display: flex;
    align-items: center;
  }
  .header-container .wrapper .right.m-right .menu-icon {
    margin-right: 14px;
  }
  .header-container .wrapper .right.m-right .menu-icon svg {
    font-size: 20px;
  }
  .header-container .wrapper .right.m-right .wallet-status {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    position: relative;
    top: -2px;
  }
  .header-container .wrapper .right.m-right .wallet-status.on-line {
    background: #8aeda0;
  }
  .header-container .wrapper .right.m-right .wallet-status.off-line {
    background: #fe6779;
  }
}
.header-container .wrapper .right .list {
  height: 100%;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  right: -4px;
}
@media screen and (max-width: 800px) {
  .header-container .wrapper .right .list {
    display: none;
  }
}
.header-container .wrapper .right .list .item {
  cursor: pointer;
  position: relative;
  width: 60px;
  height: 100%;
  justify-content: center;
  display: inline-block;
  line-height: 1;
  display: flex;
  align-items: center;
  text-align: center;
}
.header-container .wrapper .right .list .item.activate .title {
  opacity: 0;
}
.header-container .wrapper .right .list .item.activate .micon {
  font-size: 24px;
  position: relative;
}
.header-container .wrapper .right .list .item.activate .micon svg {
  opacity: 1;
}
.header-container .wrapper .right .list .item.activate .micon:after {
  content: '';
  position: absolute;
  width: 11px;
  height: 3px;
  left: 7px;
  border-radius: 3px;
  background: #fff;
  top: 28px;
}
.header-container .wrapper .right .list .item .micon {
  font-size: 24px;
  position: relative;
}
.header-container .wrapper .right .list .item .title {
  display: none;
  position: absolute;
  color: #fff;
  top: 28px;
  opacity: 0.5;
}
.header-container .wrapper .right .list .item svg {
  color: #fff;
  opacity: 0.5;
}
.header-container .wrapper .right .list .item:hover .title {
  display: block;
}
.header-container .wrapper .right .list .item:hover svg {
  opacity: 0.9;
}
.header-container .wrapper .right .list .item.user-photo-item .user-photo {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
.header-container .mobile-menu,
.header-container .mask {
  display: none;
}
@media screen and (max-width: 800px) {
  .header-container .mobile-menu.mask,
  .header-container .mask.mask {
    opacity: 0;
    z-index: 16;
    visibility: hidden;
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .header-container .mobile-menu.mask.is-open,
  .header-container .mask.mask.is-open {
    opacity: 1;
    visibility: visible;
  }
  @supports not (backdrop-filter: blur(4px)) {
    .header-container .mobile-menu.mask.is-open,
    .header-container .mask.mask.is-open {
      background: hsla(0, 0%, 7%, 0.6);
    }
  }
  @supports (backdrop-filter: blur(4px)) or (-webkit-backdrop-filter: blur(4px)) {
    .header-container .mobile-menu.mask.is-open,
    .header-container .mask.mask.is-open {
      background-color: hsla(0, 0%, 7%, 0.6);
      -webkit-backdrop-filter: blur(6px);
      backdrop-filter: blur(6px);
    }
  }
  .header-container .mobile-menu.mobile-menu.is-open,
  .header-container .mask.mobile-menu.is-open {
    display: block;
    position: fixed;
    z-index: 900;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    overflow: auto;
    padding-bottom: 30px;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap,
  .header-container .mask.mobile-menu.is-open .wrap {
    color: #fff;
    position: relative;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .close-btn,
  .header-container .mask.mobile-menu.is-open .wrap .close-btn {
    display: inline-block;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 30px;
    top: 22px;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .close-btn svg,
  .header-container .mask.mobile-menu.is-open .wrap .close-btn svg {
    font-size: 16px;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .menu-list,
  .header-container .mask.mobile-menu.is-open .wrap .menu-list {
    text-align: right;
    padding-top: 70px;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .menu-list .item,
  .header-container .mask.mobile-menu.is-open .wrap .menu-list .item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 32px;
    height: 50px;
    line-height: 50px;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .menu-list .item .wallet-status,
  .header-container .mask.mobile-menu.is-open .wrap .menu-list .item .wallet-status {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-right: 4px;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .menu-list .item .wallet-status.on-line,
  .header-container .mask.mobile-menu.is-open .wrap .menu-list .item .wallet-status.on-line {
    background: #8aeda0;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .menu-list .item .wallet-status.off-line,
  .header-container .mask.mobile-menu.is-open .wrap .menu-list .item .wallet-status.off-line {
    background: #fe6779;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .menu-list .item .title,
  .header-container .mask.mobile-menu.is-open .wrap .menu-list .item .title {
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .menu-list .item .micon,
  .header-container .mask.mobile-menu.is-open .wrap .menu-list .item .micon {
    margin-left: 12px;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .menu-list .item .micon svg,
  .header-container .mask.mobile-menu.is-open .wrap .menu-list .item .micon svg {
    font-size: 26px;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .menu-list .item .user-photo,
  .header-container .mask.mobile-menu.is-open .wrap .menu-list .item .user-photo {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    margin-left: 9px;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .menu-list .item .address,
  .header-container .mask.mobile-menu.is-open .wrap .menu-list .item .address {
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .contact-container,
  .header-container .mask.mobile-menu.is-open .wrap .contact-container {
    padding-top: 30px;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .contact-container .contact-content,
  .header-container .mask.mobile-menu.is-open .wrap .contact-container .contact-content {
    display: flex;
    justify-content: flex-end;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .contact-container .contact-content .item,
  .header-container .mask.mobile-menu.is-open .wrap .contact-container .contact-content .item {
    margin-right: 30px;
    cursor: pointer;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .contact-container .contact-content .item:hover,
  .header-container .mask.mobile-menu.is-open .wrap .contact-container .contact-content .item:hover {
    opacity: 0.3;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .contact-container .contact-content .item svg,
  .header-container .mask.mobile-menu.is-open .wrap .contact-container .contact-content .item svg {
    font-size: 44px;
  }
  .header-container .mobile-menu.mobile-menu.is-open .wrap .search-content,
  .header-container .mask.mobile-menu.is-open .wrap .search-content {
    margin-top: 50px;
    width: calc(100% - 30px - 30px);
    margin-left: 30px;
    border: 1px solid rgba(172, 143, 255, 0.2);
  }
}
.header-container .shim {
  display: none;
  width: 100%;
  height: 80px;
}
.header-container:not(.index-header) .shim {
  display: block;
}
@media screen and (max-width: 800px) {
  .header-container .modal-userinfo {
    width: 335px !important;
  }
}
.header-container .modal-userinfo-content {
  padding-top: 30px;
  padding-bottom: 20px;
}
.header-container .modal-userinfo-content .row1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-container .modal-userinfo-content .row1 .left img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.header-container .modal-userinfo-content .row1 .right {
  padding-left: 10px;
}
.header-container .modal-userinfo-content .row1 .right .item-row1 {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  line-height: 1;
  position: relative;
}
.header-container .modal-userinfo-content .row1 .right .item-row1 .on-line {
  width: 6px;
  height: 6px;
  background: #75c473;
  border-radius: 100%;
  margin-left: 5px;
  position: relative;
  top: 1px;
}
.header-container .modal-userinfo-content .row1 .right .item-row2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  color: #ffffff;
  margin-top: 3px;
}
.header-container .modal-userinfo-content .row2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 34px;
  margin-bottom: 60px;
}
.header-container .modal-userinfo-content .row2 .label {
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 4px;
}
.header-container .modal-userinfo-content .row2 .value {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
}
.header-container .modal-userinfo-content .row3 {
  display: flex;
  justify-content: center;
  gap: 30px;
}
@media screen and (max-width: 800px) {
  .header-container .modal-userinfo-content .row3 {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}
.header-container .modal-userinfo-content .row3 .ant-btn {
  width: 130px;
  height: 40px;
  background: rgba(172, 143, 255, 0.08);
  border-radius: 10px;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 800px) {
  .header-container .modal-userinfo-content .row3 .ant-btn {
    width: 275px;
  }
}
.header-container .modal-userinfo-content .row3 .ant-btn:hover {
  background: rgba(172, 143, 255, 0.3);
}
.header-container .modal-userinfo-content .row3 .ant-btn .micon {
  font-size: 16px;
  display: flex;
  align-items: center;
}
.header-container .modal-userinfo-content .row3 .ant-btn .micon[name='icon58'] {
  position: relative;
  top: 2px;
}
.header-container .modal-userinfo-content .row3 .ant-btn .micon[name='icon59'] {
  position: relative;
  top: 1px;
}
.top-container-new {
  width: 100%;
  color: #fff;
  display: flex;
  margin-bottom: 60px;
  padding-top: 61px;
  padding-left: 40px;
}
@media screen and (max-width: 800px) {
  .top-container-new {
    display: none;
  }
}
.top-container-new .avatar {
  width: 80px;
  height: 80px;
  background: rgba(196, 196, 196, 0.5);
  border-radius: 40px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-container-new .addressAndEnter {
  margin-top: 10px;
  margin-left: 18px;
  display: flex;
  flex-direction: column;
}
.top-container-new .addressAndEnter .address {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.top-container-new .addressAndEnter .enter {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.top-container-new .addressAndEnter .enter img {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-right: 20px;
}
.tabs-card-container-new {
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media screen and (max-width: 800px) {
  .tabs-card-container-new {
    display: none;
  }
}
.tabs-card-container-new .filter img {
  width: 20px;
  height: 20px;
}
.tabs-card-container-new .tabs-filter .title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #3b82f6;
  line-height: 16px;
  padding: 12px 0;
  padding-left: 40px;
  background: #1d1d23;
  cursor: pointer;
  z-index: 30;
}
.tabs-card-container-new .tabs-filter .filter_filter {
  width: 300px;
  height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: rgba(172, 143, 255, 0.08);
  color: #fff;
}
.tabs-card-container-new .tabs-filter .filter_filter .checkout {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}
.tabs-card-container-new .tabs-filter .filter_filter .checkout .checkout_outer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  word-break: break-all;
}
.tabs-card-container-new .tabs-filter .filter_filter .checkout .checkout_outer .pic6 {
  width: 30px;
  height: 30px;
  margin: 0 5px;
}
.tabs-card-container-new .tabs-filter .filter_filter::-webkit-scrollbar {
  width: 3px;
  height: 2px;
  box-shadow: inset 0 0 6px transparent;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.tabs-card-container-new .tabs-filter .filter_filter::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 transparent;
  background: rgba(228, 228, 228, 0.2);
}
.tabs-card-container-new .tabs-filter .filter_filter::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  height: 2px;
  border-radius: 10px;
}
.tabs-card-container-new .tabs-filter .filter_filter::-webkit-scrollbar-corner {
  display: none;
  height: 0;
  width: 0;
}
.select_pic5 {
  width: 16px;
  height: 16px;
  margin-top: 5px;
  margin-right: 50px;
  margin-left: 40px;
  color: #fff;
  cursor: pointer;
  position: relative;
}
.select_pic5 img {
  position: absolute;
  z-index: 3;
}
.select_pic5 .select_blank1 {
  position: absolute;
  width: 105px;
  height: 20px;
  left: -40px;
  background-color: #1d1d23;
  top: 6px;
  z-index: 0;
}
.select_title {
  color: #fff;
  position: relative;
  margin-right: 60px;
}
.select_title input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.select_title .select_blank2 {
  position: absolute;
  right: -60px;
  bottom: -5px;
  width: 380px;
  height: 4px;
  background-color: #1d1d23;
}
.select_input {
  width: 300px;
  height: 30px;
  background: rgba(172, 143, 255, 0.08);
  border-radius: 10px;
  border: none;
  color: rgba(255, 255, 255, 0.3);
}
.site-custom-tab-bar {
  z-index: 1;
  background: #1d1d23;
}
.ant-skeleton-title {
  width: 80% !important;
}
.top-mobile-container-new {
  width: 100%;
  color: #fff;
  display: none;
  padding-top: 20px;
  padding-left: 20px;
}
@media screen and (max-width: 800px) {
  .top-mobile-container-new {
    display: flex;
    flex-direction: column;
  }
}
.top-mobile-container-new .avatar_address {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  align-items: center;
}
.top-mobile-container-new .avatar_address .avatar {
  width: 40px;
  height: 40px;
  background: rgba(196, 196, 196, 0.5);
  border-radius: 20px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}
.top-mobile-container-new .avatar_address .address {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  margin-left: 10px;
}
.top-mobile-container-new .enter {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}
.top-mobile-container-new .enter img {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-right: 20px;
}
.tabs-card-mobile-container {
  display: none;
}
@media screen and (max-width: 800px) {
  .tabs-card-mobile-container {
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
.search {
  display: none;
}
@media screen and (max-width: 800px) {
  .search {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  .search .select_input {
    width: 80%;
    height: 50px;
    margin-left: 10%;
  }
}
.select_pic55 {
  width: 16px;
  height: 16px;
  margin-left: 20px;
  margin-right: 20px;
  color: #fff;
  cursor: pointer;
}
.drawer_title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.drawer_title .img {
  width: 16px;
  height: 16px;
}
.drawer_title .word {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #3B82F6;
  margin-left: 11px;
}
.drawer_close {
  width: 20px;
  height: 20px;
  color: #3B82F6;
}
.ant-drawer-content {
  background: #23262F;
  color: #fff;
}
.ant-drawer-header {
  border-bottom: 1px solid rgba(172, 143, 255, 0.2);
}
.ant-divider-horizontal {
  border-top: 1px solid rgba(172, 143, 255, 0.2);
  margin: 8px 0;
  background: 0 0;
}
.ant-checkbox-wrapper {
  color: rgba(255, 255, 255, 0.5);
}
.ant-checkbox-wrapper:hover {
  color: #ffffff;
}
.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}
.ant-checkbox-inner {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.ant-checkbox-checked .ant-checkbox-inner {
  background: #3B82F6;
  border-color: #3B82F6;
  border-radius: 4px;
}
.ant-checkbox-checked + span {
  color: #ffffff;
}
.ant-drawer-close {
  display: none!important;
}
.filter_filter {
  color: #fff;
}
.filter_filter .checkout {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}
.filter_filter .checkout .checkout_outer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.filter_filter .checkout .checkout_outer .pic6 {
  width: 30px;
  height: 30px;
  margin: 0 5px;
}
.create-container .ant-modal {
  overflow: hidden;
  border-radius: 10px;
}
.create-container .ant-modal .ant-modal-content,
.create-container .ant-modal .ant-modal-header {
  background: #23262f;
  color: #fff;
}
.create-container .ant-modal .ant-modal-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
}
.create-container .ant-modal .ant-modal-header {
  border-bottom: none;
  padding: 34px 24px 20px 24px;
  line-height: 1;
}
.create-container .ant-modal .ant-input-clear-icon {
  color: #fff;
}
.create-container .ant-modal .ant-modal-close {
  top: 10px;
  right: 8px;
}
.create-container .ant-modal .ant-modal-close .ant-modal-close-x .anticon {
  color: #fff;
  opacity: 0.6;
  font-size: 26px;
}
.create-container .ant-modal .ant-modal-close:hover .ant-modal-close-x .anticon {
  opacity: 0.9;
}
.create-container .custom-modal-1 .ant-modal-body {
  padding: 0 93px 60px 93px;
  height: 286px;
  overflow-y: auto;
  width: 98%;
  margin: auto;
}
.create-container .custom-modal-1 .ant-modal-footer {
  border: none;
  text-align: center;
  padding-bottom: 30px;
  padding-top: 10px;
}
.create-container .custom-modal-1 .ant-modal-footer .primary-btn {
  margin: auto;
  width: 220px;
  height: 50px;
}
.create-container .select-license-spinning .ant-spin-spinning {
  position: absolute !important;
}
.create-container .select-license-nft-modal .ant-modal {
  width: 700px !important;
  height: 474px !important;
  overflow: hidden;
  border-radius: 10px;
}
.create-container .select-license-nft-modal .ant-modal .ant-modal-body {
  padding-top: 10px;
}
.create-container .select-license-nft-modal .form-item-search {
  width: 100%;
}
.create-container .select-license-nft-modal .form-item-search .ant-input-affix-wrapper {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(172, 143, 255, 0.2);
  border-radius: 10px;
}
.create-container .select-license-nft-modal .form-item-search .ant-input-affix-wrapper .ant-input {
  background: none;
}
.create-container .select-license-nft-modal .form-item-search .ant-input-affix-wrapper-sm {
  padding-left: 10px;
}
.create-container .select-license-nft-modal .form-item-search .ant-input-prefix {
  margin-right: 10px;
}
.create-container .select-license-nft-modal .form-item-license-list {
  margin: auto;
}
.create-container .select-license-nft-modal .form-item-license-list .list {
  width: 100%;
  margin: auto;
  max-height: 260px;
  min-height: 200px;
  overflow-y: auto;
}
.create-container .select-license-nft-modal .form-item-license-list .list .list-wrapp {
  column-count: 2;
  width: 620px;
  margin: auto;
}
.create-container .select-license-nft-modal .form-item-license-list .list .list-wrapp .group {
  margin-bottom: 20px;
}
.create-container .select-license-nft-modal .form-item-license-list .list .list-wrapp .group .item {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
}
.create-container .select-license-nft-modal .form-item-license-list .list .list-wrapp .group .item:hover {
  background: rgba(172, 143, 255, 0.08);
}
.create-container .select-license-nft-modal .form-item-license-list .list .list-wrapp .group .item .item-left .img-wrap {
  border-radius: 4px;
  overflow: hidden;
}
.create-container .select-license-nft-modal .form-item-license-list .list .list-wrapp .group .item .item-left .img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.create-container .select-license-nft-modal .form-item-license-list .list .list-wrapp .group .item .item-left .default-img {
  width: 40px;
  height: 40px;
  font-size: 40px;
  opacity: 0.3;
}
.create-container .select-license-nft-modal .form-item-license-list .list .list-wrapp .group .item .item-right {
  padding: 6px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.create-container .select-license-nft-modal .form-item-license-list .list .list-wrapp .group .item .item-right .row1 {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.5);
}
.create-container .select-license-nft-modal .form-item-license-list .list .list-wrapp .group .item .item-right .row2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}
.create-container .select-license-nft-modal .form-item-license-list .list .no-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
}
.create-container .select-license-nft-modal .form-item-license-list .list .no-data .title {
  margin-bottom: 60px;
  margin-top: 10px;
}
.create-container .select-license-nft-modal .form-item-license-list .list .no-data button {
  width: 220px;
  height: 50px;
  border: 1px solid rgba(172, 143, 255, 0.3);
  border-radius: 8px;
  background: none;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}
.create-container .custom-form-1 .ant-input-affix-wrapper {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(172, 143, 255, 0.2);
  border-radius: 10px;
  padding-left: 6px;
}
.create-container .custom-form-1 .ant-input-affix-wrapper .ant-input {
  background: none;
}
.create-container .custom-form-1 .ant-input-prefix {
  width: 30px;
  height: 30px;
  background: rgba(54, 54, 54, 0.5);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 1px;
  margin-right: 8px;
}
.create-container .custom-form-1 .ant-input-prefix .micon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-container .custom-form-1 .ant-space {
  width: 100%;
}
.create-container .custom-form-1 .ant-space .ant-space-item:first-child {
  width: 280px;
}
.create-container .custom-form-1 .ant-space .ant-space-item:first-child .ant-input-affix-wrapper {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.create-container .custom-form-1 .ant-space .ant-space-item:last-child {
  width: 240px;
}
.create-container .custom-form-1 .ant-space .ant-space-item:last-child .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.create-container .custom-form-1 .ant-form-item {
  margin-bottom: 10px;
}
.create-container .custom-form-1 .ant-input {
  line-height: 2;
  border-radius: 10px;
}
.create-container .custom-form-1 .tips {
  margin: auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 20px;
  width: 460px;
}
.create-container .custom-form-1 .list-title {
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 36px;
}
.create-container .custom-form-1 .list-title .left {
  padding-left: 40px;
  margin-right: 204px;
}
.create-container .custom-form-1 [name='icon57'] {
  width: 40px;
  height: 40px;
  font-size: 40px;
  cursor: pointer;
}
.create-container .custom-form-1 [name='icon57'] circle {
  opacity: 0.2;
}
.create-container .custom-form-1 [name='icon57'] g {
  opacity: 0.7;
}
.create-container .custom-form-1 [name='icon57']:hover circle {
  opacity: 1;
}
.create-container .custom-form-1 [name='icon57']:hover g {
  opacity: 1;
}
.create-container .add-levels-form .ant-form-item {
  margin-bottom: 0;
}
.create-container .add-levels-form .ant-space {
  width: 100%;
}
.create-container .add-levels-form .ant-space .ant-space-item:first-child {
  width: 290px;
}
.create-container .add-levels-form .ant-space .ant-space-item:last-child {
  float: 1;
}
.create-container .add-levels-form .ant-space .ant-space-item .form-value-item .ant-form-item-control-input-content {
  display: flex;
}
.create-container .add-levels-form .ant-space .ant-space-item .form-value-item .ant-form-item-control-input-content .form-of-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 42px;
}
.create-container .add-levels-form .ant-space .ant-space-item .form-value-item .ant-form-item-control-input-content .form-start-item .ant-input {
  border-radius: 0;
}
.create-container .creation-progress-modal .ant-modal-body {
  padding: 24px 52px;
}
.create-container .creation-progress-modal .footer {
  text-align: center;
}
.create-container .creation-progress-modal .creation-progress-container .wrap .progress {
  margin-bottom: 40px;
}
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .title {
  display: flex;
  margin-bottom: 20px;
}
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .title .left {
  width: 24px;
  height: 24px;
  margin-right: 22px;
  position: relative;
}
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .title .left .label {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  line-height: 24px;
  top: 0px;
}
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .title .left .circle,
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .title .left .semicircle,
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .title .left .completed {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 24px;
}
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .title .left .circle > .anticon,
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .title .left .semicircle > .anticon,
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .title .left .completed > .anticon {
  position: absolute;
}
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .title .left .semicircle {
  animation: rotate 1.6s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .title .right {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
}
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .status-content {
  display: flex;
}
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .status-content .left {
  width: 24px;
  height: 24px;
  margin-right: 22px;
}
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .status-content .right .status-completed {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 20px;
}
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .status-content .right .status-progress {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
}
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .status-content .right .status-progress.progress1 {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #06f201;
  margin-top: 20px;
  height: 18px;
}
.create-container .creation-progress-modal .creation-progress-container .wrap .progress .status-content .right .status-progress.progress2 {
  color: rgba(255, 255, 255, 0.7);
}
.create-container .billboards-container {
  color: #fff;
  text-align: center;
  width: 100%;
  padding: 30px;
  padding-top: 26vh;
}
.create-container .billboards-container .title {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 30px;
}
.create-container .billboards-container .subtitle {
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 30px;
}
.create-container .billboards-container .btns {
  display: flex;
  column-gap: 30px;
  justify-content: center;
}
.create-container .billboards-container .btns .ant-btn {
  width: 200px;
  height: 48px;
  border-radius: 48px;
}
.create-container .billboards-container .btns .ant-btn span {
  font-size: 16px;
}
.create-container .billboards-container .ant-btn:hover,
.create-container .billboards-container .ant-btn:focus,
.create-container .billboards-container .ant-btn:active {
  color: #000;
}
.create-container .white-color {
  color: #fff;
}
.create-container .ant-form-item-label > label {
  color: #fff;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  font-size: 18px;
}
.create-container .ant-form-item-label > label .title {
  display: flex;
  align-items: center;
}
.create-container .ant-form-item-label > label .title .star {
  color: #fe6779;
  margin-left: 6px;
}
.create-container .ant-form-item-label > label .subtitle {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}
.create-container .ant-form-item-label > label .subtitle span {
  color: #fff;
}
.create-container .ant-input {
  line-height: 2.4;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(172, 143, 255, 0.2);
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
}
.create-container textarea.ant-input {
  height: 140px;
}
.create-container .ant-select {
  color: #fff;
  font-size: 16px;
  line-height: 30px;
}
.create-container .ant-select-item {
  color: #fff;
  line-height: 30px;
}
.create-container .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px;
  border-radius: 6px;
}
.create-container .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(172, 143, 255, 0.1);
}
.create-container .ant-select-dropdown {
  background-color: #1d1d23;
  border: 1px solid rgba(172, 143, 255, 0.1);
}
.create-container .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgba(255, 255, 255, 0.06);
}
.create-container .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(255, 255, 255, 0.2);
}
.create-container .ant-select-single .ant-select-selector .ant-select-selection-item,
.create-container .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 46px;
}
.create-container .ant-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.create-container .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid rgba(172, 143, 255, 0.2);
  -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.create-container .ant-empty-small {
  color: #fff;
}
.create-container .ant-spin-nested-loading > div > .ant-spin {
  max-height: 400px;
  position: absolute;
  max-height: 100%;
}
.create-container .ant-spin-container:after {
  background: none;
}
.create-container .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background: none;
}
.create-container .ant-upload-list {
  color: #fff;
}
.create-container .ant-upload-list-picture .ant-upload-list-item-error,
.create-container .ant-upload-list-picture-card .ant-upload-list-item-error {
  border-radius: 8px;
}
.create-container .ant-upload-list-picture-card-container {
  width: 102px;
  height: 102px;
}
.create-container .ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
  border: 1px dashed rgba(255, 255, 255, 0.5);
  border-radius: 8px;
}
.create-container .ant-upload.ant-upload-select-picture-card:hover {
  background: rgba(255, 255, 255, 0.05);
  border: 1px dashed rgba(255, 255, 255, 0.5);
  border-radius: 8px;
}
.create-container .ant-upload.ant-upload-select-picture-card .micon {
  font-size: 20px;
}
.create-container .ant-upload.ant-upload-select-picture-card .micon + div {
  margin: 0 !important;
}
.create-container .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.create-container .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: none;
}
.create-container .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background: #1d1d23;
}
.create-container .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 100%;
}
.create-container .ant-upload-list-picture .ant-upload-list-item,
.create-container .ant-upload-list-picture-card .ant-upload-list-item {
  border-radius: 8px;
}
.create-container .m-cover .ant-upload-select-picture-card {
  width: 670px;
  height: 200px;
}
.create-container .m-cover .ant-upload-select-picture-card .micon {
  font-size: 40px;
}
.create-container .m-cover .ant-upload-list-picture-card-container {
  width: 670px;
  height: 200px;
}
.create-spinning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(0, 0, 0, 0.7);
}
.create-spinning .ant-spin-spinning {
  position: fixed !important;
}
.create-spinning .ant-spin-blur {
  opacity: 0.1;
}
.create-spinning .ant-spin-text {
  text-shadow: none !important;
}
.create-container .primary-btn {
  height: 40px;
  background: rgba(59, 130, 246, 0.5);
  border: none;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  cursor: pointer;
}
.create-container .primary-btn:hover,
.create-container .primary-btn:active,
.create-container .primary-btn:focus {
  background: #3b82f6;
}
.create-container .secondary-btn {
  height: 40px;
  background: rgba(98, 59, 209, 0.5);
  border: none;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  cursor: pointer;
}
.create-container .secondary-btn:hover,
.create-container .secondary-btn:active,
.create-container .secondary-btn:focus {
  background: #623bd1;
}
.create-container .c-header {
  padding: 60px 0px 20px 0;
  border-bottom: 1px solid rgba(172, 143, 255, 0.2);
  width: calc(100% - 80px);
  margin: auto;
  margin-bottom: 10px;
  text-align: left;
}
.create-container .c-header .title {
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.create-container .c-header .title .btns {
  display: flex;
  column-gap: 20px;
}
.create-container .c-header .tips {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.5);
  padding: 0;
}
.create-container .c-header .tips .star {
  color: #fe6779;
  margin-right: 4px;
}
.create-container .form-content {
  width: 730px;
  margin: auto;
  padding: 30px;
}
.create-container .supply-limit-inline {
  margin-bottom: 0;
}
.create-container .supply-limit-inline .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
}
.create-container .supply-limit-inline .ant-form-item-control-input-content .ant-form-item {
  width: 49%;
}
.create-container .form-license-nft-item .wrap {
  display: flex;
  flex-wrap: wrap;
}
.create-container .form-license-nft-item .wrap .nft-item {
  width: 80px;
  height: 80px;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.create-container .form-license-nft-item .wrap .nft-item img {
  object-fit: contain;
}
.create-container .form-license-nft-item .wrap .nft-item .default-img {
  width: 80px;
  height: 80px;
  font-size: 80px;
  opacity: 0.3;
  border: none;
}
.create-container .form-license-nft-item .wrap .nft-item .default-img:hover {
  border: none;
}
.create-container .form-license-nft-item .wrap .nft-item .status-0 {
  position: absolute;
  background: none;
  border: none;
  width: 20px;
  height: 20px;
  font-size: 20px;
  bottom: 6px;
  right: 6px;
}
.create-container .form-license-nft-item .wrap .nft-item .status-0:hover {
  border: none;
}
.create-container .form-license-nft-item .wrap .nft-item .status-1 {
  position: absolute;
  background: none;
  border: none;
  width: 20px;
  height: 20px;
  font-size: 20px;
  bottom: 6px;
  right: 6px;
}
.create-container .form-license-nft-item .wrap .nft-item .status-1:hover {
  border: none;
}
.create-container .form-license-nft-item .wrap .nft-item .status-2 {
  position: absolute;
  background: none;
  border: none;
  width: 20px;
  height: 20px;
  font-size: 20px;
  bottom: 6px;
  right: 6px;
}
.create-container .form-license-nft-item .wrap .nft-item .status-2:hover {
  border: none;
}
.create-container .form-license-nft-item .wrap .nft-item .delete-btn {
  position: absolute;
  background: none;
  border: none;
  width: 28px;
  height: 28px;
  background: #000000;
  opacity: 0.4;
  font-size: 12px;
  bottom: 6px;
  border-radius: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.create-container .form-license-nft-item .wrap .nft-item .delete-btn:hover {
  opacity: 0.9;
  background: #000000;
  border: none;
  border-radius: 100%;
}
.create-container .form-license-nft-item .wrap .micon {
  width: 80px;
  height: 80px;
  border: 1px dashed rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.create-container .form-license-nft-item .wrap .micon:hover {
  background: rgba(255, 255, 255, 0.05);
  border: 1px dashed rgba(255, 255, 255, 0.5);
  border-radius: 8px;
}
.create-container .special-item {
  border-bottom: 1px solid rgba(172, 143, 255, 0.1);
}
.create-container .special-item .special-item-label {
  display: flex;
  justify-content: space-between;
  width: 670px;
  height: 58px;
  padding-bottom: 10px;
}
.create-container .special-item .special-item-label .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.create-container .special-item .special-item-label .left .row1 {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 18px;
}
.create-container .special-item .special-item-label .left .row1 .micon svg {
  font-size: 18px;
  margin-right: 8px;
}
.create-container .special-item .special-item-label .left .row2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.5);
  padding-left: 25px;
}
.create-container .special-item .special-item-label .right .micon {
  cursor: pointer;
  font-size: 45px;
  line-height: 1;
  opacity: 0.5;
}
.create-container .special-item .special-item-label .right .micon:hover {
  opacity: 1;
}
.create-container .special-item .ant-form-item-control .ant-form-item-control-input {
  min-height: 0;
}
.create-container .special-item.form-properties-item .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: flex-start;
  column-gap: 34px;
}
.create-container .special-item.form-properties-item .list .item {
  width: 200px;
  height: 72px;
  background: rgba(172, 143, 255, 0.08);
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.create-container .special-item.form-properties-item .list .item .type {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 6px;
}
.create-container .special-item.form-properties-item .list .item .name {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  width: 200px;
}
.create-container .special-item.form-properties-item .list .item .name span {
  color: #ffffff;
  display: inline-block;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.create-container .special-item.form-levels-item .list .item {
  padding: 14px 20px;
  width: 100%;
  height: 72px;
  background: rgba(172, 143, 255, 0.08);
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.create-container .special-item.form-levels-item .list .item .wrap {
  width: 100%;
}
.create-container .special-item.form-levels-item .list .item .wrap .row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.create-container .special-item.form-levels-item .list .item .wrap .row1 .left {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
}
.create-container .special-item.form-levels-item .list .item .wrap .row1 .right {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: right;
  color: #ffffff;
}
.create-container .special-item.form-levels-item .list .item .wrap .row2 {
  width: 100%;
  height: 12px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  position: relative;
}
.create-container .special-item.form-levels-item .list .item .wrap .row2 .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #3b5bd1;
  border-radius: 6px;
}
.create-container .special-item.form-stats-item .list .item {
  padding: 0 20px;
  width: 100%;
  height: 36px;
  background: rgba(172, 143, 255, 0.08);
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.create-container .special-item.form-stats-item .list .item .wrap {
  width: 100%;
}
.create-container .special-item.form-stats-item .list .item .wrap .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.create-container .special-item.form-stats-item .list .item .wrap .row .left {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
}
.create-container .special-item.form-stats-item .list .item .wrap .row .right {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: right;
  color: #ffffff;
}
.create-container .footer {
  text-align: center;
  margin-top: 70px;
}
.create-container .footer .create-btn {
  width: 400px;
}
.create-container .footer .edit-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 40px;
}
.create-container .footer .edit-btns .cancel-btn {
  width: 180px;
  height: 50px;
  background: none;
  color: #fff;
  border-radius: 8px;
  font-size: 18px;
  border: 1px solid rgba(172, 143, 255, 0.3);
}
.create-container .footer .edit-btns .cancel-btn:hover {
  border: 1px solid #ac8fff;
}
.create-container .footer .edit-btns .save-btn {
  width: 180px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
}
.create-container .goto-opensea {
  position: relative;
}
.create-container .goto-opensea::after {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: -2px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.preview-modal .ant-modal-content {
  background-color: var(--backgroundColor);
}
.preview-modal .ant-modal-close-x {
  color: #fff;
}
.preview-modal .ant-modal-header {
  background: var(--backgroundColor);
  color: #fff;
  border-bottom: 1px solid rgba(172, 143, 255, 0.2);
}
.preview-modal .ant-modal-header .ant-modal-title {
  color: #fff;
}
.ori-particle-approve-popup {
  position: fixed;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 6px;
  padding: 0px 18px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  -webkit-box-shadow: 4px 4px 20px 1px rgba(180, 180, 180, 0.6);
  box-shadow: 4px 4px 20px 1px rgba(180, 180, 180, 0.6);
  top: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  right: -600px;
  width: 379px;
  height: 78px;
}
@media screen and (max-width: 600px) {
  .ori-particle-approve-popup {
    max-width: 90%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 0px 12px;
    right: 0;
    top: -100px;
  }
}
.ori-particle-approve-popup.ori-particle-approve-popup-show {
  right: 30px;
}
@media screen and (max-width: 600px) {
  .ori-particle-approve-popup.ori-particle-approve-popup-show {
    right: 0;
    top: 30px;
  }
}
.ori-particle-approve-popup .ori-particle-pam-left {
  position: relative;
  padding-right: 20px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 600px) {
  .ori-particle-approve-popup .ori-particle-pam-left {
    padding-right: 10px;
  }
}
.ori-particle-approve-popup .ori-particle-pam-left:after {
  content: '';
  position: absolute;
  width: 1px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  right: -6px;
  top: 0;
  height: 100%;
}
.ori-particle-approve-popup .ori-particle-pam-left .ori-particle-pam-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ori-particle-approve-popup .ori-particle-pam-left .ori-particle-pam-wrap .ori-particle-pam-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 42px;
  height: 42px;
  min-width: 42px;
  margin-right: 10px;
  border-radius: 42px;
  position: relative;
  overflow: hidden;
}
.ori-particle-approve-popup .ori-particle-pam-left .ori-particle-pam-wrap .ori-particle-pam-img img {
  width: 100%;
  height: 100%;
}
.ori-particle-approve-popup .ori-particle-pam-left .ori-particle-pam-wrap .ori-particle-pam-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.ori-particle-approve-popup .ori-particle-pam-left .ori-particle-pam-wrap .ori-particle-pam-content .ori-particle-pam-title {
  font-weight: bold;
  font-size: 17px;
  color: #000;
}
.ori-particle-approve-popup .ori-particle-pam-left .ori-particle-pam-wrap .ori-particle-pam-content .ori-particle-pam-text {
  font-size: 12px;
  color: #666;
  opacity: 0.8;
  line-height: 12px;
}
.ori-particle-approve-popup .ori-particle-pam-right {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: #2d6af6;
  font-weight: bold;
  margin-left: 20px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ori-particle-approve-popup .ori-particle-pam-right:hover {
  color: #2d6af6;
  font-weight: bold;
}
