@import '../../../../../styles/const.less';

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 700px;
    color: #fff;
}
.title {
    font-weight: 700;
    font-size: 24px;
}
.infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.info {
    text-align: center;
}
.auth {
    width: 100%;
}
.expired {
    width: 100%;
}
.amount {
    width: 90px;
}
.image {
    position: relative;
    width: 150px;
    height: 150px;
    margin-top: 30px;
    margin-bottom: 10px;
    background-color: rgba(172, 143, 255, 0.08);
    border-radius: 8px;
    img {
        object-fit: contain;
        border-radius: 8px;
    }
}
.icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 30px;
    height: 30px;
}
.desc {
    font-weight: 400;
    font-size: 14px;
    color: @secondary-color;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.name {
    margin-top: 10px;
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 18px;
}
.label {
    font-size: 14px;
    color: @secondary-color;
}
.user {
    display: flex;
    align-items: center;
}
.field {
    display: flex;
    align-items: center;
}
.input {
    // width: 70px;
    margin-right: 5px;
    margin-bottom: 0px;
}
.infos {
    width: 430px;
}
.button {
    width: 220px;
    height: 50px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    border-radius: 8px;
    border: 0;
    color: #fff;
    background-color: rgba(59, 130, 246, 0.5);
    &:hover,
    &:active,
    &:focus {
        background-color: rgba(59, 130, 246, 0.3);
    }
}
.mint {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
}
.mint-label {
    display: flex;
    align-items: center;
    > span {
        font-size: 14px;
        font-weight: 400;
        color: @secondary-color;
    }
    > img {
        width: 14px;
        height: 14px;
        margin-left: 5px;
    }
}
.mint-value {
    display: flex;
    align-items: center;
    > img {
        width: 16px;
        height: 24px;
        margin-right: 10px;
    }
    > span {
        font-weight: 700;
        font-size: 24px;
        color: #fff;
    }
}
.warning {
    margin-top: 30px;
    font-size: 12px;
    font-weight: 400;
    color: @minor-color;
}

@media (max-width: 600px) {
    .box {
        display: flex;
        flex-direction: column;
        // justify-content: flex-start;
        // align-items: flex-start;
        // width: 700px;
        color: #fff;
    }
    .title {
        font-weight: 700;
        font-size: 24px;
    }
    .infoContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-top: 8vw;
        margin-bottom: 8vw;
    }

    .image {
        flex-shrink: 0;
        position: relative;
        width: 13.3333vw;
        height: 13.3333vw;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-right: 2.6667vw;
        background-color: aqua;
        border-radius: 8px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }
    }
    .info {
        text-align: left;
    }
    .icon {
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 4vw;
        height: 4vw;
    }

    .desc {
        font-weight: 400;
        font-size: 14px;
        color: @secondary-color;
    }
    .name {
        margin-top: 0px;
        margin-bottom: 0px;
        font-weight: 500;
        font-size: 18px;
    }
    .infos {
        padding-left: 2.6667vw;
        margin: 0 12vw;
    }
    .input {
        // width: 18.6667vw;
        max-width: 26.6667vw;
        margin-right: 5px;
        margin-bottom: 0px;
    }

    .button {
        width: 78.6667vw;
        height: 13.3333vw;
    }
    .mint {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 5.3333vw 0;
    }
    .mint-label {
        > img {
            width: 3.7333vw;
            height: 3.7333vw;
            margin-left: 1.3333vw;
        }
    }
    .mint-value {
        display: flex;
        align-items: center;
        > img {
            width: 4.2667vw;
            height: 6.4vw;
            margin-right: 2.6667vw;
        }
    }
}
