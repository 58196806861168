.ori-particle-approve-popup {
    position: fixed;
    z-index: 100;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 6px;
    padding: 0px 18px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    -webkit-box-shadow: 4px 4px 20px 1px rgba(180, 180, 180, 0.6);
    box-shadow: 4px 4px 20px 1px rgba(180, 180, 180, 0.6);
    top: 30px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    right: -600px;
    width: 379px;
    height: 78px;
}
@media screen and (max-width: 600px) {
    .ori-particle-approve-popup {
        max-width: 90%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        padding: 0px 12px;
        right: 0;
        top: -100px;
    }
}
.ori-particle-approve-popup.ori-particle-approve-popup-show {
    right: 30px;
}
@media screen and (max-width: 600px) {
    .ori-particle-approve-popup.ori-particle-approve-popup-show {
        right: 0;
        top: 30px;
    }
}
.ori-particle-approve-popup .ori-particle-pam-left {
    position: relative;
    padding-right: 20px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
@media screen and (max-width: 600px) {
    .ori-particle-approve-popup .ori-particle-pam-left {
        padding-right: 10px;
    }
}
.ori-particle-approve-popup .ori-particle-pam-left:after {
    content: '';
    position: absolute;
    width: 1px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    right: -6px;
    top: 0;
    height: 100%;
}
.ori-particle-approve-popup .ori-particle-pam-left .ori-particle-pam-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.ori-particle-approve-popup .ori-particle-pam-left .ori-particle-pam-wrap .ori-particle-pam-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 42px;
    height: 42px;
    min-width: 42px;
    margin-right: 10px;
    border-radius: 42px;
    position: relative;
    overflow: hidden;
}
.ori-particle-approve-popup .ori-particle-pam-left .ori-particle-pam-wrap .ori-particle-pam-img img {
    width: 100%;
    height: 100%;
}
.ori-particle-approve-popup .ori-particle-pam-left .ori-particle-pam-wrap .ori-particle-pam-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.ori-particle-approve-popup
    .ori-particle-pam-left
    .ori-particle-pam-wrap
    .ori-particle-pam-content
    .ori-particle-pam-title {
    font-weight: bold;
    font-size: 17px;
    color: #000;
}
.ori-particle-approve-popup
    .ori-particle-pam-left
    .ori-particle-pam-wrap
    .ori-particle-pam-content
    .ori-particle-pam-text {
    font-size: 12px;
    color: #666;
    opacity: 0.8;
    line-height: 12px;
}
.ori-particle-approve-popup .ori-particle-pam-right {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    color: #2d6af6;
    font-weight: bold;
    margin-left: 20px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.ori-particle-approve-popup .ori-particle-pam-right:hover {
    color: #2d6af6;
    font-weight: bold;
}
