.top-container-new{
    width:100%;
    color:#fff;
    display: flex;
    margin-bottom:60px;
    padding-top: 61px;
    padding-left: 40px;
    @media screen and (max-width: 800px) {
        display: none;
    }
    .avatar{
        width:80px;
        height:80px;
        background: rgba(196, 196, 196, 0.5);
        border-radius: 40px;
        font-size:40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .addressAndEnter{
        margin-top:10px;
        margin-left:18px;
        display: flex;
        flex-direction: column;
        .address{
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
        }
        .enter{
            display: flex;
            flex-direction:row;
            margin-top:10px;
            img{
                width:20px;
                height:20px;
                border-radius:20px;
                margin-right:20px;
            }
        }
    }
}