:root {
    --backgroundColor: #1d1d23;
}
@import 'antd/dist/antd.less';
@import './mixins.less';
@import './scroll.less';
@import './loading.less';
html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Lexend', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
        Droid Sans, Helvetica Neue, sans-serif !important;
    background-color: var(--backgroundColor);
}

body.noscroll {
    // width: calc(100% - 15px);
    overflow: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    margin: 0;
}

.normal-modal {
    .ant-modal-content {
        background-color: transparent;
    }
    .modal-close {
        width: 40px;
        height: 40px;
    }
}
@media (prefers-color-scheme: dark) {
    html {
        color-scheme: dark;
    }

    body {
        color: white;
        background-color: #1d1d22;
    }
}

img {
    width: 100%;
    height: 100%;
    display: block;
}

.micon.anticon svg {
    fill: none;
}

::selection {
    color: inherit;
    background: rgba(69, 162, 254, 0.5);
}

.hidden {
    display: none;
}

.particle-wallet-entry-container {
    display: none !important;
}

// [data-1155='true'] {
//     background: red;
// }
