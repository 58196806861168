@import '../../../styles/const.less';

.input {
    width: 100%;
    height: 40px;

    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(172, 143, 255, 0.2);
    border-radius: 10px;
    text-align: center;
    color: #fff;
    font-size: 16px;

    &:hover,
    &:focus {
        border: 0;
    }
    &::placeholder {
        color: @minor-color;
        text-align: center;
    }
}
