.banner-container {
    height: 1560px;
    position: relative;
    width: 100%;
    @media screen and (max-width: 800px) {
        height: calc(1470px + 44vw);
    }
    .orb-canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1600px;
        pointer-events: none;
        z-index: 11;
        left: 50%;
        transform: translateX(-50%);
        z-index: 4;
        @media screen and (max-width: 800px) {
            height: calc(1340px + 44vw);
        }
    }

    .img-wrap {
        width: 1360px;
        position: relative;
        margin: auto;
        height: 100%;
        @media screen and (max-width: 800px) {
            width: 100%;
            overflow: hidden;
        }
        .item-img {
            position: absolute;
            &.banner-planet {
                left: 50%;
                transform: translateX(-50%);
                width: 800px;
                height: 800px;
                top: 100px;
                z-index: 2;
                @media screen and (max-width: 800px) {
                    width: 600px;
                    height: 600px;
                    top: 440px;
                    left: 50vw;
                }
            }
            &.banner-astronaut {
                width: 300px;
                height: 356px;
                z-index: 5;
                top: 220px;
                left: 100px;
                @media screen and (max-width: 800px) {
                    top: 70px;
                    left: initial;
                    right: -60px;
                    width: 240px;
                    height: 284px;
                }
            }
            &.banner-ascard {
                width: 750px;
                height: 793px;
                z-index: 1;
                top: 240px;
                @media screen and (max-width: 800px) {
                    width: 500px;
                    height: 530px;
                    top: -70px;
                    left: -120px;
                }
            }
            &.banner-rocket {
                right: 30px;
                top: 100px;
                width: 620px;
                height: 478px;
                z-index: 5;
                @media screen and (max-width: 800px) {
                    width: 520px;
                    height: 398px;
                    top: 350px;
                }
            }
        }
    }
    .banner-content-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        .row {
            text-align: center;
            &.row1 {
                padding-top: 340px;
                font-style: normal;
                font-weight: 700;
                font-size: 96px;
                line-height: 96px;
                text-align: center;
                color: #fff;
                @media screen and (max-width: 800px) {
                    padding-top: 620px;
                    font-size: 0px;
                    line-height: 56px;
                }
            }
            &.row2 {
                padding-top: 20px;
                font-style: normal;
                font-weight: 300;
                font-size: 28px;
                line-height: 36px;
                text-align: center;
                max-width: 416px;
                margin: auto;
                color: #fff;
                opacity: 0.8;
                @media screen and (max-width: 800px) {
                    font-size: 32px;
                    padding-top: 12px;
                }
            }
            &.row3 {
                padding-top: 22px;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.3;
                color: rgba(255, 255, 255, 0.5);
                @media screen and (max-width: 800px) {
                    padding-top: 20px;
                }
                p {
                    line-height: 1.4;
                    margin: 0;
                }
            }
            &.row4 {
                padding-top: 40px;
                .wrap {
                    display: flex;
                    justify-content: center;
                    .btn {
                        background: none;
                        border: none;
                        position: relative;
                        width: 120px;
                        height: 50px;
                        padding: 0;
                        position: relative;
                        cursor: pointer;
                        margin: 0 2px;
                        .micon {
                            width: 150px;
                            height: 50px;
                            position: absolute;
                            left: -15px;
                            top: 0;
                            &[name='icon17'] {
                                svg {
                                    width: 100%;
                                    height: 100%;
                                    path[data-name='path1'] {
                                        fill-opacity: 0.3;
                                    }
                                }
                            }
                            &[name='icon18'] {
                                svg {
                                    width: 100%;
                                    height: 100%;
                                    path[data-name='path1'] {
                                        opacity: 0.6;
                                    }
                                }
                            }
                        }
                        &:hover {
                            .micon {
                                &[name='icon17'] {
                                    svg {
                                        path[data-name='path1'] {
                                            fill-opacity: 0.5;
                                        }
                                    }
                                }
                                &[name='icon18'] {
                                    svg {
                                        path[data-name='path1'] {
                                            opacity: 0.9;
                                        }
                                    }
                                }
                            }
                        }
                        .name {
                            position: absolute;
                            font-weight: 700;
                            font-size: 18px;
                            color: rgba(255, 255, 255, 0.9);
                            width: calc(100% - 15px);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                            top: 0;
                            left: 0;
                        }
                        &:last-child {
                            .name {
                                left: initial;
                                right: 0;
                            }
                        }
                    }
                }
            }

            &.row5 {
                padding-top: 200px;
                @media screen and (max-width: 800px) {
                    position: absolute;
                    top: 0;
                    padding-top: 100px;
                }
                .wrap {
                    display: flex;
                    position: relative;
                    justify-content: center;
                    @media screen and (max-width: 800px) {
                        flex-direction: column;
                    }
                    .box {
                        position: relative;
                        min-width: 260px;
                        padding-left: 50px;
                        padding-right: 20px;
                        opacity: 0.8;
                        @media screen and (max-width: 800px) {
                            padding-bottom: 30px;
                            padding-left: 34px;
                        }
                        &.box1 {
                            padding-left: 30px;
                        }
                        &:after {
                            content: '';
                            height: 100%;
                            top: 0%;
                            width: 1px;
                            border-left: 1px solid rgba(217, 217, 217, 0.5);
                            right: 0;
                            position: absolute;
                            @media screen and (max-width: 800px) {
                                border: none;
                            }
                        }
                        &:last-child {
                            &:after {
                                border: none;
                            }
                        }
                        .text {
                            text-align: left;
                            &.text1 {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 14px;
                                color: rgba(255, 255, 255, 0.5);
                                [name='icon14'] {
                                    opacity: 0.8;
                                    margin-left: 6px;
                                }
                            }
                            &.text2 {
                                padding-top: 20px;
                                font-weight: 700;
                                font-size: 36px;
                                line-height: 36px;
                                color: #ffffff;
                                @media screen and (max-width: 800px) {
                                    padding-top: 3px;
                                }
                            }
                            &.text3 {
                                padding-top: 15px;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 14px;
                                color: rgba(255, 255, 255, 0.5);
                                @media screen and (max-width: 800px) {
                                    padding-top: 0px;
                                }
                            }
                        }
                        &.box3 {
                            .text {
                                &.text2 {
                                    background: linear-gradient(90deg, #f800fd 1.67%, #7e00fd 50.03%, #4c9cf9 100%);
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    background-clip: text;
                                    text-fill-color: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
