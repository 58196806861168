.theme-ligth {
    /*背景颜色*/
    --bg-color: #FFFFFF;
    /* 主字体颜色 */
    --font-color1: #000000;
    /* 辅助字体颜色 */
    --font-color2: rgba(60, 66, 66, 0.6);
    /* 滚动条颜色 */
    --scoll-bar-color: rgba(0, 0, 0, 0.3);
    /* 悬浮背景 */
    --hover-color: rgba(0, 0, 0, 0.3);
    --active-color: rgba(0, 0, 0, 1);
    --active-bg-color: rgba(0, 0, 0, 1);
    --active-font-color: rgba(255, 255, 255, 1);
    --close-icon: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAAAXNSR0IArs4c6QAAA79JREFUaEPdm7uO1TAQhv/lfl8uFW9BR0/PE9BQIEQLEhIIUSEEEhK0CFHQ8AT09HS8Bd2y3Je9os/yrLw5yYnt2IecjBQJ7Ukcf5nx7/HYrKiOHZF0VNJhfx2StOIv3rjnr11JO/7akrRduju8tJQBdFzSMUkA5RjAm5L+SgJ4sA0F5PmTkk4MgOqCAHZD0h/v7SzYXEADAy63jdgOE85AZoHmdI5QPOPHVmwnS9zHWP2ZGrqpgKd9OKY+VwLQxImw/RXbYGxHEY1zklDHMRhq+10S43SuxQACt/ofQrKv74Tstz7IPsCxwhl8L+Q8QH7Dc2MJyy6PEq54ErWdsXmAwKGYy2AkBUBGA6KWzHHLZMyTM+ra5kG8hmL2jc+xwROiKOuBFK8N4sIIFTP2YzIe18Obm4CEJeG5zEaYEq7OQkD+fXEJQ7PpDCb/r6aqIeApSVxTsN+SuA54EO/lruPG9lHw4loIyCIV5ZySoaibFqJn/Wp8COBlSc8lfZb0KrOhu5KuSHog6UtmG/YYVYEfBlgiPF9Iuulbfy3pcWIHn0i64595J+l+4vNtYrMGILnm+YGN8fg1Se+D3DUFMoRjLrsh6WOBPq0DSD2FFXoJuy7pTSJkE+62pA8lOkMFAEDggCxlKZA14eDZsCVR6VVDDGRtOACditbKPedBLgIOwB1Lz2pN8G2QvNjUEkEpOeaaw8wBXqqcfzYhrRO14XjP3iIAeRGQb4NUkFTqVkG17BJIB1hiku9T4HDM2b0p82Rf+12/79YUGXtpCEcVDGPXCasN6cZgzeJSm1oClpoM5Hpwq8ZE3+a5pqDEzJO5UOFzbqIvmarFwNk9i4B0qVqpZDsFblGQLtnGSilpToZSy5NuVW+AJRJuMpKn/oOlTuJNyEdeiIaMQ7bZXIhiJNuo6RB7KOmeP0iQk36FkC8lPRvSGV/KdypqNjRMmdvITihZfMrs3FVfsiDrsTkzp6mZohONTL5sOJXCL3sUlAzddlqzdD8FL+4XfdsA+VutBXDOWEp9hnFL2X7f2naXlrkI7Iq9fYD8PukNUPsA1ErHvj9vfZ3ZF7Qf5u3ijv2EhTHMPWnRt03N5E2GU6solSoizfuZ0NnR7TwQ1AdIg2M75RSGZe9ppxhAm07YXitdIM71IAcNgGs9GxOjol0vJhFYxBHKrvfb0Uq3extjsR4M20JZWV4tWmFRSo5TJh17zgE0WEodeLS2ACEgeIz1XbINAbSXEbJcpUEBs5O+yWAx82Bqo5M8lN71EUbz3wr+AYmK2/Eo29nbAAAAAElFTkSuQmCC);
    --more-icon: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAOCAYAAADE84fzAAAAAXNSR0IArs4c6QAAALBJREFUOE+l1N8NwiAQx/Evk5gmLtANfHGOxgWMu3SBdo2+6AgO4DDmlEsoAXoALw3k+Pyuf6jjPy7ACVj9vOfyAN7Ay3n46bUFuHXIsn/y+4cYl/XWgBAWZxRcQU1sCYhhuftF8Z6AJCxgiLcEZOEUXhNQhHO4JeAQLuGlABN8hKcCPsA5OAe/ryJ3LuIXmqqLO9WaImzpXCHzowi7s3Su9RtwBWbgbvlF1OAWb1fzBRrFKl8Qs4flAAAAAElFTkSuQmCC);
    --more-icon-fff: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAOCAYAAADE84fzAAAAAXNSR0IArs4c6QAAALZJREFUOE+l1MsNwjAQRdH7KkFINEAHbKgDpQFELzRg2sgGSqAAehnkyKAQ/Jkk3tmanGdNbAvAzA7ARtItztcMM7sAT0kPJfiewCCpW4qbWQBO6fvtFI/riwImcHT2Sm0ZJ84OyMCdpDDgawJKcDS/+JKAGvyHzwlowVncE+CBi3gtwAtX8ULAC9iN7sFwKkr34ueH5ooyO/2UVeHmzivHtAm78dSiHjgCV0lnzxPRbIsHKdW8AZKJbFk8CuGzAAAAAElFTkSuQmCC);
    --back-icon: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAAAXNSR0IArs4c6QAAA5hJREFUaEPdm8luFDEQhv+w7+trcOcFQICE4MR658KNO0cEB04cQUgIngUkxA0J8RgQSICQkAR9lj3q9HSP3T2ubs+UZAmle2x/XeWqctmsyEYOSDooab9v+ySt+MaIu77tSNr2bUvSv9zTYdBcAtBhSYckAdRHAN6U9FcSwHPLvID8/qikI3NAtUEAuyHpj9d2L9i+gAEMuL59pE4YcwayF2ifyWGKJ/zaSp1kjvdYq+tdTbcr4HFvjl1/lwMwOCfM9ldqh6kTxWmckoR3LEHwtj8lsU5nSgogcKdHMMnY3DHZHzHIGGCpcAE+CjkLkGdorhSzbNMo5oom8bZTMgsQODzmIghJAZDJgHhLYtwiCXFyyrs2aRCt4TFj67M0eEwUz7onxWuCOFugx0z9mKzH1erLdUDMEvNcZMFMMVcnVUD+fW4BTbOuDIL/9+BVq4DHJNGGEPaJzyVdlPRQ0ufMg/6WRNujQbTXdx/XZX7AvZJ0w//opaTHXTpIeBctfqsCsknFc1pLHY71ctNAg3DgUTeDiZ70u3FLwCa425I+GQ1KVWAtAFqb59BwfDNnpgCSa54x+op0OwZcwFkFkHoKO3QLGRMOnnUAgQMyt4wNB89G2BLl3jWUAAeg86K5c89S4ADcDulZrgBPPH0t6Zq3d+KcZSiILSsHeD5j/vlU0gM/KqnSLcM4F4Pj+a4lIOW9e5Lep8zE6B0HmDPIE1PfSbpc0eJ9SR+MAGLd7lg4GSDfSLpaAKRbgxbFJcIOkFdGhtyyDPQlQLpAb5mqjQ3pUjXrZLsJ8o6kjzEPkeG5S7aRnJ60aV51SBKAu8aQk+0SE7JKuKuwQBJCLlWyHEtI4rAzUYTB8abWQir3tgZ5XdIXg4Ep5TsvGsTaTMM4dcgXkp5kBpwqOtH/kGVDIJ9JuiDpkaSvmQEby4bLUvjljIKSoTtOq5fuh9RiZqVNuptorwmQv+XeAFuBNPXLiS9l+4k0nS4NVQS2AHfF3hggz5f6ADR8AGqlpZ/Ph7lOnQuGB7NOcUu/YREYZt60iB1TUyEjw8lVlMq97gjonOi2XgiKATKh0m45Vc0yetspBTCEE47XcheI+2qUiwbANd6NSfGibQOTCAxxhbJt/HC10p3epkiqBqt94VnZXg3tYfGUXKfsdO25D2CApdSBRq0dEA4EjbG/6yzzAIbBMFlablDAwk3fzmApcbBrp0t5Kb3tIxTz3wr+A84tyfE2Wc4aAAAAAElFTkSuQmCC);
}

.theme-dark {
    /*背景颜色*/
    --bg-color: #1a1b20;
    /* 主字体颜色 */
    --font-color1: #ffffff;
    --font-color2: rgba(255, 255, 255, 0.6);
    /* 滚动条颜色 */
    --scoll-bar-color: rgba(255, 255, 255, 0.7);

    /* 悬浮背景 */
    --hover-color: rgba(255, 255, 255, 0.3);
    --active-bg-color: rgba(255, 255, 255, 1);
    --active-font-color: rgba(0, 0, 0, 1);
    --close-icon: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAAAXNSR0IArs4c6QAABDdJREFUaEPdmlluFDEQhv9i3xGb2Hce4ALcgwMgLgDvwAEQ73ABxDvcgwvwhJIQAgnZCCGZZMKk0D+yo56Ou9vutofulDSakdquqa+9VNlVggSiqocAHAdwEMABAPvNh78pfwEMMt9bAP6ICL+jisTSZqBOAjgB4HBNvRsEBbAaC7YxoKoS6FwDqKJ3QdglESFwbakNqKpHAVwAcKT2v/t1JOiCiKz7NR9tFQyoqpx+BDtW5w8b9FkzoJshOoIAVfWsmY5B/UIMqmirABZFZMlXp5ehqsp2FwGc8lWcuN1vAHMiQuBSqQRUVW7tV8aw1qpszT/n2vwuInQ5hVIKqKrcQAhn/VeoEanbE25GRArXZSGgmZbXWzhyrpGcLpquZYCXAdBxd0EYGPxwGeoEVNUzxhV0Ac7aOC8iy3mDdwGakOsmgMoNqGX03FGnRKSftcsFeNUEyi2z38ucNRGZKQQ0cSV3zS7Lt2xYtzOCZte8ZY44XQbcFJEpC5AF7OLGUjQQP0XkFx8OAVWVB9LbAPZ1eegytvMwPSkiAwvIk8G1PQJnMej8exaQxx9O0SbCoOA1gM8A3tZU9AzAfQDPeaqvqcN242F5wQJyc+E9ShN5CeCJUfABwItAZXw5j0yfdwBeBfbPN++LyKQYx07ApvIQwPuMkhDILBxVPAbwqalBw3UYOSzjCNBYKz6QeThOz48R4KhinoCxg+oQyJRwBFwlYIrQzAcyNRwB1wjIwLruPWbZTCqDHAccbesR8E7CE7sLkju33S1pRMw1l3/hWwS8lziCyUNmjUgJx//ZJuBdkzeItHE51bggU8PRkAEBYzj5qpeTX3Ns7+NCqvRWPe8TkDFoyltqF1yIn6yCKHu+nsIPZv/QtVvyeWgwUBdy6AdjBNouA8pcgY+frAuV7bdMQF7HX4qhLaPDx8+NA3KWgLy15mE31i2aD5x9Fykhecs2YY9LsaKZELjUkD0RmbaA5wEwNdZE8qMR4uea9C2ymWm2RQvIJMuNJnQAeBp/anSEwLlGkjcCbxra81VENrK3ajEiGp7oeWVR97DKQ/MDADzRN5GBiHyhgr16bbiTp8hf/HI3bWsu0HdEmTOcsOm0EdegqqdNqtpXWRvbMbW9Yg1zJV84iqxQ6qKMXNuPrEFLo6q83+Q9TReFOfuRwqGiBCgrKjhduyQrIjKXN7gshZ3iMirVC9uVFyxcg5mpykQMixBSXEjFBGWFBfMQ2y6lVWUkdBmEbOumw/JLwhXWylSeIFpcCNQzhUBMlRVKJSB7trCUi5mn2SilXNlX05JiPIZhw+ytj3iNYA6Smw6PVyxZHqekL6fMgfImjqB7qyA2P2Qm8mF2ODbo/y1pdoDSpXBUOXX5zcKGEOFuyLJlTkU67tLd0Vdx8Br0VWxKMZkWpw/lx/6mCvovflh2Nfzm6dtXd0i7f4OccmrSCbV4AAAAAElFTkSuQmCC);
    --more-icon: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAOCAYAAADE84fzAAAAAXNSR0IArs4c6QAAALZJREFUOE+l1MsNwjAQRdH7KkFINEAHbKgDpQFELzRg2sgGSqAAehnkyKAQ/Jkk3tmanGdNbAvAzA7ARtItztcMM7sAT0kPJfiewCCpW4qbWQBO6fvtFI/riwImcHT2Sm0ZJ84OyMCdpDDgawJKcDS/+JKAGvyHzwlowVncE+CBi3gtwAtX8ULAC9iN7sFwKkr34ueH5ooyO/2UVeHmzivHtAm78dSiHjgCV0lnzxPRbIsHKdW8AZKJbFk8CuGzAAAAAElFTkSuQmCC);
    --more-icon-fff: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAOCAYAAADE84fzAAAAAXNSR0IArs4c6QAAALZJREFUOE+l1MsNwjAQRdH7KkFINEAHbKgDpQFELzRg2sgGSqAAehnkyKAQ/Jkk3tmanGdNbAvAzA7ARtItztcMM7sAT0kPJfiewCCpW4qbWQBO6fvtFI/riwImcHT2Sm0ZJ84OyMCdpDDgawJKcDS/+JKAGvyHzwlowVncE+CBi3gtwAtX8ULAC9iN7sFwKkr34ueH5ooyO/2UVeHmzivHtAm78dSiHjgCV0lnzxPRbIsHKdW8AZKJbFk8CuGzAAAAAElFTkSuQmCC);
    --back-icon: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAAAXNSR0IArs4c6QAABEJJREFUaEPdm0lv1TAQx//Dvu87iH2HCx8AAQe+BCckTix3BOLAFSEWcefGtwHBiUJpWYootKWlLGXpOuj/5FR5afLivOdJAiNFrRTH9s9je8bjeQIDUdWlAFYAWAJgEYCFAOYBmO+amwYwA2ASwLh7xkTkd+juSKgKVXUlgDUA+HdBm/VOAfgBYFRExtqso+mzjgBVlRrZBGBtB1BZHIQdBTAkItR4W9IWoKpyum0GsN5NvbYa9/yIcCMOlNO6kBQGdFNxh1tXhRrrsDDX6wcR4RT2Fm9AVWXZrU5r3t9598SvoAIYBjAgIvw/V7w6qqrcBXcD4O5YB+Fu+05EqNWWkguoqtzm97rtPq++Mt9PAHgjIvybKS0BHdy+Ctab70BRg72tNJkJqKq0ZYRb7NtaReXoKBAy1ZSkAroNhXDLKup00WZ/AXidtvFkAW4DsKFoKxWX/ywin5J9mAOoqvQh9wDI3YAqBko2T7PxNuniNUG4qXnwH1h3WWPL9fgqPlWTgPQrt9RMM0W7QydgKPpoFtA5zodjR5qiFdelPHfTl9GuGgek88ynDOEp5AaAEwCuA+gK3OisFuOARw2OPGn9JtwtAGfdy0cAbgcG5FHrBddiA1BVVzlfM3A7c6pLwtF+XTDQIBumr/o9AtwFYLUxXRrcJQDPjNr9JiJ9EeAx482lbDiO2bSIPBdVpTu232gUWW0VcBFOLwHpktE1s5Aq4cjzkYAMP6wzoKsajkhfCMhTw/LAgHWAI9IYAem98NQeSuoCR55xAh4JeGJn7IZG+4wbLdo5S1OQp5RJAh4PGNu8CuCca5WBoYuGdi4Pju9nLAH/ALgC4LFPT4zKNABD+qCM49wHcDKmRUI+MQLIq3bKYpMh5B0Ap2sAOWFlJgh5F8CpiiF/Whr6OkA2DL2lq1Y1ZMNVs3a20yBpG5/m7RAB3veWdVxKQtIBuGwMOSUiXRHgTnf9HGDQMqtImhBryK8i8r7skAVduXsxO0nI8wC6DUa2KWRBUPqk7SYPFOlfEvIhgAdFKvAo2xx04geqWmbYkJDXABwCcBNAj0enixQZFJFBfpAM/FKLTDD4l4WJCgwZNq7TkqH7MrVoNYiz2ksDJDCnTcgDsBVIWr28zu7OvHxxa7GsILAFeGPnjFecdQG63aWLWHTCqs4REelPVt7qCvuAS6az6lDIenm47vG+wnZTta7pI8mB4brj/Xxqzsz/kEZCuMxcmdx7eJflxDt75n7WSTgteSffMtspF9BNV8Y6eQPFBIU6CHNJ+3zSLL0AIyJV5f39xgozMJhJwXSRAd9RLgTotMkDMs1I2Yl5jLP2iwhPIN5SGDCmTYY6mJVhfQLhyYBZv0yjLCxtAzpt0jEnKJ/QoAQj1LCIFM70jUaiI8D4cLpMYF6D09VrF5ZQdLV4Gq8+KT1rvsR+VsBMRT6tflZAG8Yt3+RnBX8BGG560bLz030AAAAASUVORK5CYII=);
}

/* 定义 dark 模式的颜色 */
@media (prefers-color-scheme: dark) {}
.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.start-start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.start-center {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.start-end {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.center-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.center-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex1 {
    flex: 1;
}

.hover:hover {
    cursor: pointer;
    opacity: 0.8;
}
/* apercu pro light
apercu pro mono
apercu pro regular
apercu pro medium
apercu pro bold
apercu pro black

Alliance-No-1-Regular
Alliance-No-1-Medium
Alliance-No-1-SemiBold
Alliance-No-1-Bold
Alliance-No-2-Bold
Alliance-No-2-ExtraBold */

@font-face {
  font-family: "Apercu Pro-Light";
  font-style: normal;
  src: url(./28be9dd099f83f772537.woff2) format("woff2"),
    url(./4b7a914cbace85c0370e.ttf) format("truetype");
  font-display: swap
}

@font-face {
  font-family: "Apercu Pro-Mono";
  font-style: normal;
  src: url(./9648c3712860bee53dc0.woff2) format("woff2"),
    url(./a801ff78818bdccf6c36.ttf) format("truetype");
  font-display: swap
}


@font-face {
  font-family: "Apercu Pro";
  font-style: normal;
  src: url(./160762ff8241faf1079d.woff2) format("woff2"),
    url(./e30a80c3fef0330cd075.ttf) format("truetype");
  font-display: swap
}

@font-face {
  font-family: "Apercu Pro-Medium";
  font-style: normal;
  src: url(./eec9aa1e95c90c631345.woff2) format("woff2"),
    url(./0d47bce0cfcc3ce98ec5.ttf) format("truetype");
  font-display: swap
}

@font-face {
  font-family: "Apercu Pro-Bold";
  font-style: normal;
  src: url(./f48df80bece7687592a9.woff2) format("woff2"),
    url(./bb271a99110e39838dc1.ttf) format("truetype");
  font-display: swap
}

@font-face {
  font-family: "Apercu Pro-Black";
  font-style: normal;
  src: url(./d8283942e6bb00eeb56c.woff2) format("woff2"),
    url(./c3386cb3faa54bfbcbff.ttf) format("truetype");
  font-display: swap
}

/* ---------------------------------- */


@font-face {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 200;
  src: url(./28be9dd099f83f772537.woff2) format("woff2"),
    url(./4b7a914cbace85c0370e.ttf) format("truetype");
  font-display: swap
}

@font-face {
  font-family: "Apercu Pro";
  font-style: italic;
  font-weight: 400;
  src: url(./9648c3712860bee53dc0.woff2) format("woff2"),
    url(./a801ff78818bdccf6c36.ttf) format("truetype");
  font-display: swap
}

@font-face {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 450;
  src: url(./9648c3712860bee53dc0.woff2) format("woff2"),
    url(./a801ff78818bdccf6c36.ttf) format("truetype");
  font-display: swap
}

@font-face {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  src: url(./160762ff8241faf1079d.woff2) format("woff2"),
    url(./e30a80c3fef0330cd075.ttf) format("truetype");
  font-display: swap
}

@font-face {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 600;
  src: url(./f48df80bece7687592a9.woff2) format("woff2"),
    url(./bb271a99110e39838dc1.ttf) format("truetype");
  font-display: swap
}

@font-face {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 800;
  src: url(./d8283942e6bb00eeb56c.woff2) format("woff2"),
    url(./c3386cb3faa54bfbcbff.ttf) format("truetype");
  font-display: swap
}


.connect-body {
  font-family: 'Apercu Pro';
}

.user-bar-container {
    display: inline-flex;
}

.connect-wallet-btn {
    display: inline-flex;
    background-color: #0e76fd;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    padding: 10px 14px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: all .125s ease;
    cursor: pointer;
    user-select: none;
    width: 100%;
    height: 100%;
    text-align: center;
}

.connect-wallet-btn:hover {
    transform: scale(1.01);
}

.connect-wallet-btn:active {
    opacity: 0.8;
}

.account-info {
    /* width: 200px; */
    display: inline-flex;
    overflow: hidden;
    background-color: var(--bg-color);
    border-radius: 10px;
    padding: 10px 14px;
    font-size: 16px;
    font-weight: 400;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: nowrap;
    cursor: pointer;
    transition: all 0.14s;
    color: var(--font-color1);
}

.unsupported-chain-button {
    /* width: 200px; */
    display: inline-flex;
    overflow: hidden;
    background-color: #FF494A;
    border-radius: 10px;
    padding: 10px 14px;
    font-size: 16px;
    font-weight: 400;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: nowrap;
    cursor: pointer;
    transition: all 0.14s;
    color: #fff;
}


.account-info.chain {
    margin-right: 8px;
}

.account-info:hover,
unsupported-chain-button:hover {
    transform: scale(1.01);
}

.avatar-img {
    border-radius: 3px;
    margin-right: 10px;
    overflow: hidden;
    background-color: var(--bg-color);
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.chain-icon {
    width: 100%;
    height: 100%;
}

.account-info span,
.unsupported-chain-button span {
    margin-right: 4px;
}

.icon-more {
    width: 11.44px;
    height: 6.75px;
    background-image: var(--more-icon);
    background-size: cover;
}

.unsupported-chain-button .icon-more {
    background-image: var(--more-icon-fff);
}

.unsupported-tips {
    color: var(--font-color2);
    font-size: 14px;
    margin: 20px 0 10px;
    font-weight: 500;
}
.account-modal-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.39);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1998;
    visibility: hidden;

}

.account-modal-container.show {
    visibility: initial;
}

@keyframes model-box-show {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }

}

.account-modal-box {
    max-width: 100%;
    width: 350px;
    height: 261px;
    border: 1px solid var(--bg-color);
    background-color: var(--bg-color);
    border-radius: 21px;
    opacity: 0;
    transform: translateY(100px);
    visibility: hidden;
    position: fixed;
    top: calc(50vh - 121.5px);
    left: calc(50vw - 175px);
    z-index: 1999;
    overflow: hidden;
}

.account-modal-box.show {
    visibility: initial;
    animation: model-box-show .18s ease forwards;
}

.account-modal-title {
    font-size: 18px;
    height: 60px;
    line-height: 60px;
    font-weight: 800;
    color: var(--font-color1);
    margin-left: 6px;
    width: 100%;

}

.account-modal-close {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    background-image: var(--close-icon);
    background-size: cover;
    z-index: 9;
    transition: all 0.1s;
}


.avatar {
    width: 80px;
    height: 80px;
    margin-right: 0;
    margin-bottom: 17px;
    border-radius: 50%;
    overflow: hidden;

}

.account-address {
    font-size: 16px;
    font-weight: 500;
    line-height: 12px;
    color: var(--font-color1);
    opacity: 1;
    width: 162px;
    height: 30px;
    background: var(--bg-color);
    opacity: 1;
    border-radius: 30px;
}

.logout {
    width: 171px;
    height: 47px;
    background: #EA4335;
    opacity: 1;
    font-weight: 600;
    border-radius: 30px;
    font-size: 17px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 37px;
    cursor: pointer;
    transition: all 0.3s;

}

.logout:hover {
    transform: scale(1.02);

}

.logout:active {
    opacity: 0.8;
}

.icon-copy {
    width: 18px;
    height: 18px;
    margin-left: 10px;
    cursor: pointer;
}
.connect-form-contaier {
    width: 100%;
    height: 100%;
    position: relative
}



.form-footer {
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    color: var(--font-color1);

}

.qrcode {
    width: 380px;
    height: 380px;
    box-sizing: border-box;
    border: 1px solid #E9EAEB;
    border-radius: 10px;
    margin-top: 50px;
    margin-bottom: 20px;
    background-color: #fff;
}

.qrcode img {
    width: 100%;
    height: 100%;
}

.loading>img {
    width: 68px;
    height: 68px;
    border-radius: 10px;
}

.loading h3 {
    font-size: 18px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 800;
    line-height: 30px;
    color: var(--font-color1);
}

.loading p {
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    color: var(--font-color2);

    margin: 0;

}

@keyframes loading-inner {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-img {
    width: 30px;
    height: 30px;
    margin-top: 20px;
    animation: loading-inner 1.5s linear infinite;
}

.open-qrcode {
    width: 53px;
    height: 28px;
    background-color: rgba(0, 0, 0, 0.06);
    font-size: 12px;
    text-align: center;
    border-radius: 20px 20px 20px 20px;
    line-height: 28px;
    margin-left: 30px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: #000;
    font-weight: 800;
}

.tips {
    margin-top: 10px;
    font-size: 12px;
    color: var(--font-color2);
    width: 100%;
    padding: 0 40px;
    box-sizing: border-box;
    margin-bottom: 10px;
}
.connect-modal-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.39);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1998;
    visibility: hidden;
    overflow: hidden;

}

.connect-modal-container.show {
    visibility: initial;
}

@keyframes model-box-show {
    0% {
        transform: translateY(200px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }

}

.connect-modal-box {
    width: 748px;
    height: 526px;
    border: 1px solid var(--bg-color);
    background-color: var(--bg-color);
    border-radius: 20px;
    opacity: 0;
    transform: translateY(200px);
    visibility: hidden;
    position: fixed;
    top: calc(50vh - 263px);
    left: calc(50vw - 374px);
    z-index: 1999;
    overflow: hidden;
}




.connect-modal-box.show {
    visibility: initial;

    animation: model-box-show .18s ease forwards;
}

.connect-modal-title {
    font-size: 18px;
    height: 60px;
    line-height: 60px;
    font-weight: 600;
    color: var(--font-color1);
    margin-left: 6px;
    width: 100%;
}

.connect-modal-close {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    background-image: var(--close-icon);
    background-size: cover;
    z-index: 9;
    transition: all 0.1s;
}

.connect-modal-close:hover {
    transform: scale(1.1);
}

.connect-methods-list {
    padding: 0 0px 0 22px;
    box-sizing: border-box;

}

.connect-methods-form {
    width: 446px;
    height: 526px;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.connect-modal-group-name {
    padding: 10px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    color: #868989;
    max-width: 100%;
    overflow-x: hidden;
    margin-left: 6px;
}

.connect-modal-group-item {
    font-size: 17px;
    font-weight: 500;
    line-height: 12px;
    color: var(--font-color1);
    width: 260px;
    height: 41px;
    overflow: hidden;
    transition: all 0.3s;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 4px;

}

.connect-modal-group-item img {
    width: 30px;
    height: 30px;
}

.group-icon-box {
    height: 30px;
    width: 30px;
    margin-left: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 13px;
    margin-left: 6px;
    overflow: hidden;
    border-radius: 5px;
}

.connect-modal-group-item.active {
    background-color: var(--active-bg-color);
    color: var(--active-font-color) !important;
    transform: scale(1) !important;

}

.item-hover1:active {
    transform: scale(0.95);
}

.item-hover1:hover {
    background-color: var(--hover-color);
    color: var(--font-color1);
}

.connect-more {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: var(--font-color1);
    width: 100%;
    padding-right: 28px;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    cursor: pointer;

}

.connect-more .icon_more {
    width: 11.44px;
    height: 6.75px;
    margin-left: 4px;
    border-radius: 4px;
    transition: all 0.3s;
    background-image: var(--more-icon);
    background-size: cover;

}

.connect-more .icon_more.show {
    transform: rotate(180deg);
}


.connect-modal-gourp-list {
    height: 460px;
    overflow-y: auto;
    padding-bottom: 20px;
    box-sizing: border-box;

}

.connect-modal-gourp-list::-webkit-scrollbar-thumb {
    width: 8px;
    background: var(--scoll-bar-color);
    margin-right: 2px;
    border: none;
    border-radius: 10px;

}

.no-auth-key {
    width: 100%;
    height: 100%;
    padding: 30px;
    box-sizing: border-box;
    font-size: 14px;
}

.item-what {
    margin-top: 30px;
    margin-bottom: 28px;
    width: 100%;
}

.no-auth-key h3 {
    font-size: 18px;
    width: 100%;
    margin-top: 0;
    width: 100%;
    font-weight: 600;
    text-align: center;
    color: var(--font-color1);
}

.item-what h5 {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--font-color1);
    max-width: 276px;
    margin: 0;
}

.item-what p {
    font-size: 14px;
    font-weight: 400;
    color: var(--font-color2);
    margin: 0;
    line-height: 22px;
    max-width: 276px;

}

.icon-what {
    width: 48px;
    height: 48px;
    margin-right: 26px;
    user-select: none;
    margin-left: 22px;
    pointer-events: none;
}

.icon-back {
    display: none;
}


/* min-pc */
@media screen and (max-width:768px) {
    .connect-modal-box {
        max-width: 368px;
        left: calc(50vw - 184px);
    }

    .connect-methods-list.hide {
        display: none;
    }

    .connect-modal-title {
        text-align: center;
        padding-right: 22px;
        box-sizing: border-box;
    }

    .connect-modal-group-item {
        min-width: 326px;

    }

    .connect-methods-form {
        display: none;
        max-width: 100%;
        box-sizing: border-box;
        border: none;
    }

    .connect-methods-form.show {
        display: block;
        position: relative;

    }

    .icon-back {
        display: block;
        position: absolute;
        left: 15px;
        top: 15px;
        cursor: pointer;
        z-index: 9;
        background-image: var(--back-icon);
        width: 28px;
        height: 28px;
        background-size: 100% 100%;

    }

    .qrcode {
        max-width: 320px;
        max-height: 320px;
        padding: 10px;
    }

    .account-modal-box {}
}
.chain-modal-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.39);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1998;
    visibility: hidden;
    color: var(--font-color1);

}

.chain-modal-container.show {
    visibility: initial;
}

@keyframes model-box-show {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }

}

.chain-modal-box {
    max-width: 100%;
    width: 350px;
    min-height: 100px;
    border: 1px solid var(--bg-color);
    background-color: var(--bg-color);
    border-radius: 21px;
    opacity: 0;
    transform: translateY(100px);
    visibility: hidden;
    /* position: fixed; */
    /* left: calc(50vw - 175px); */
    /* z-index: 1999; */
    overflow: hidden;
    padding: 16px;
    box-sizing: border-box;
}

.chain-modal-box.show {
    visibility: initial;
    animation: model-box-show .18s ease forwards;
}

.chain-modal-title {
    font-size: 18px;
    height: 60px;
    line-height: 60px;
    font-weight: 800;
    color: var(--font-color1);
    margin-left: 6px;
    width: 100%;

}

.chain-modal-close {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    background-image: var(--close-icon);
    background-size: cover;
    z-index: 9;
    transition: all 0.1s;
}

.modal-title {
    font-size: 18px;
    font-weight: 800;
    color: var(--font-color1);
    width: 100%;
    margin-bottom: 10px;
}

.chain-list-box {
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
}


.not-tips {
    padding: 0 10px 0 0;
    box-sizing: border-box;
    margin-top: 15px;
    color: var(--font-color2);
    font-size: 14px;
}

.chain-list-item {
    height: 40px;
    width: 100%;
    border-radius: 12px;
    padding: 7px;
    box-sizing: border-box;
    font-weight: 700;
    color: var(--font-color1);
    margin-top: 4px;
    cursor: default;
}

.disconnect-btn {
    color: #FF494A;
    height: 40px;
    width: 100%;
    border-radius: 12px;
    box-sizing: border-box;
    font-weight: 700;
    margin-top: 10px;
    cursor: default;
}

.chain-list-item.item-hover:hover,
.disconnect-btn:hover {
    background-color: var(--hover-color);
    cursor: pointer;
    transition: all 0.3s;

}


.chain-list-item img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 10px;
}


.chain-list-item.active {
    background-color: var(--active-bg-color) !important;
    color: var(--active-font-color) !important;
}

.chain-active-connected {
    color: var(--active-font-color);
    font-size: 14px;
}

.chain-active-confirm {
    font-weight: 400;
    font-size: 12px;
}

.chain-active-confirm span {
    background-color: #FFD641;
    display: flex;
    width: 8px;
    height: 8px;
    margin-left: 6px;
    border-radius: 50%;
}

.chain-active-connected {
    display: none;
    font-weight: 400;
    font-size: 12px;
}

.chain-list-item.active .chain-active-connected {
    display: flex;
}

.chain-active-connected span {
    display: flex;
    width: 8px;
    height: 8px;
    background-color: #30E000;
    margin-left: 6px;
    border-radius: 50%;
}
