.search-content {
    width: 465px;
    height: 40px;
    border-radius: 40px;
    background: rgba(172, 143, 255, 0.08);
    position: relative;
    .input {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 18px;
        padding: 10px 20px;
        border: none;
        background: none;
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: 16px;
        &:focus,
        &:active,
        &:focus-visible {
            outline: none;
            border: none;
        }
    }
    .icon36 {
        position: absolute;
        top: 9px;
        right: 14px;
        font-size: 20px;
        opacity: 0.5;
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
    }
    .allow-clear {
        position: absolute;
        right: 38px;
        top: 7px;
        cursor: pointer;
        opacity: 0.7;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            font-size: 12px;
        }
        &:hover {
            opacity: 1;
        }
    }
}
