.contact-container {
    padding-top: 120px;
    @media screen and (max-width: 800px) {
        padding-top: 40px;
    }
    .contact-content {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 40px;
        .item {
            margin-right: 60px;
            cursor: pointer;
            &:hover {
                opacity: 0.3;
            }
            svg {
                font-size: 34px;
            }
        }
    }
}
