@import './const.less';

.primary-text {
    font-size: 16px;
    color: @primary-color;
    font-weight: 500;
}
.secondary-text {
    font-size: 13px;
    color: @secondary-color;
    font-weight: 400;
}
.button_hover {
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}
.background-config {
    background-repeat: no-repeat;
    background-size: cover;
}
.model-loading {
    position: fixed;
    display: flex;
    justify-content: center;

    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background: rgba(0, 0, 0, 0.7);
    .ant-spin-spinning {
        position: fixed !important;
    }
    .ant-spin-blur {
        opacity: 0.1;
    }
    .ant-spin-text {
        text-shadow: none !important;
    }
    .ant-spin {
        max-height: inherit !important;
    }
}
