.primary-btn {
    height: 40px;
    background: rgba(59, 130, 246, 0.5);
    border: none;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
        background: #3b82f6;
    }
}
.secondary-btn {
    height: 40px;
    background: rgba(98, 59, 209, 0.5);
    border: none;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
        background: #623bd1;
    }
}

.recommend-container {
    position: absolute;
    top: 1050px;
    width: 100%;
    z-index: 12;
    @media screen and (max-width: 800px) {
        top: calc(1090px - 8vw);
    }
    .wrapper {
        width: 990px;
        @media screen and (max-width: 800px) {
            width: 100%;
        }
        .slick-dots {
            width: 232px;
            left: 0;
            margin: 0;
            margin-left: 10px;
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 800px) {
                position: absolute;
                top: calc(44vw + 210px);
                margin: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 3px;
            }
            li {
                width: 60px;
                background: rgba(0, 0, 0, 0.5);
                border-radius: 2px;
                &.slick-active {
                    background: rgba(255, 255, 255, 0.5);
                }
            }
        }
        margin: 0 auto;
        .carousel-item-wrap {
            display: flex;
            justify-content: space-between;
            width: 990px;
            padding: 0 10px;
            margin: auto;
            @media screen and (max-width: 800px) {
                flex-wrap: wrap;
                width: 100%;
                padding: 0 4vw;
                // justify-content: center;
            }
            .m-recommend-title {
                display: none;
                @media screen and (max-width: 800px) {
                    display: block;
                    width: 100%;
                    order: 0;
                    font-style: normal;
                    font-size: 24px;
                    line-height: 36px;
                    color: #ffffff;
                    text-align: center;
                    margin-bottom: 30px;
                    span:last-child {
                        font-size: 24px;
                        margin-left: 10px;
                        font-weight: 600;
                    }
                }
            }
            .item {
                width: 300px;
                @media screen and (max-width: 800px) {
                    width: 44vw;
                }
            }
            .item.operate {
                width: 232px;
                margin-right: 40px;
                @media screen and (max-width: 800px) {
                    order: 3;
                    width: 100%;
                    margin-right: 0;
                    position: relative;
                }
                .row1,
                .row2 {
                    font-weight: 300;
                    font-size: 36px;
                    line-height: 36px;
                    color: #ffffff;
                    margin-bottom: 10px;
                    @media screen and (max-width: 800px) {
                        display: none;
                    }
                }
                .row2 {
                    margin-bottom: 30px;
                    font-weight: 500;
                }

                .row3,
                .row4 {
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: 800px) {
                        justify-content: space-between;
                        padding: 0 16px;
                        margin-top: 14px;
                    }
                    .label {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 16px;
                        color: rgba(255, 255, 255, 0.5);
                        margin-right: 10px;
                    }
                    .value {
                        display: flex;
                        align-items: center;
                        .amount {
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 16px;
                            color: #ffffff;
                        }
                    }
                }
                .row3 {
                    margin-bottom: 10px;
                }
                .row4 {
                    margin-bottom: 40px;
                }
                .row5 {
                    margin-bottom: 30px;
                    @media screen and (max-width: 800px) {
                        text-align: center;
                        margin-top: 60px;
                    }
                    [type='button'] {
                        width: 100%;
                        @media screen and (max-width: 800px) {
                            height: 50px;
                            width: 231px;
                            margin: auto;
                        }
                    }
                }
                .row6 {
                    margin-bottom: 50px;
                    @media screen and (max-width: 800px) {
                        text-align: center;
                    }
                    [type='button'] {
                        width: 100%;
                        @media screen and (max-width: 800px) {
                            height: 50px;
                            width: 231px;
                            margin: auto;
                        }
                    }
                }
                .row7 {
                    display: flex;
                    justify-content: space-between;
                    display: none;
                    .p-item {
                        width: 60px;
                        height: 4px;
                        background: rgba(0, 0, 0, 0.5);
                        border-radius: 4px;
                        &:active {
                            background: rgba(255, 255, 255, 0.5);
                        }
                    }
                }
            }
            .nft-item {
                cursor: pointer;
                .item-wrap {
                    border-radius: 6px;
                    overflow: hidden;
                    .cover {
                        width: 300px;
                        height: 300px;
                        object-fit: cover;
                        position: relative;
                        background: rgba(0, 0, 0, 0.2);
                        @media screen and (max-width: 800px) {
                            width: 44vw;
                            height: 44vw;
                        }
                        img {
                            object-fit: contain;
                        }
                        .icon {
                            position: absolute;
                            bottom: 15px;
                            right: 15px;
                            @media screen and (max-width: 800px) {
                                bottom: 10px;
                                right: 10px;
                            }
                            svg {
                                font-size: 40px;
                                @media screen and (max-width: 800px) {
                                    font-size: 30px;
                                }
                            }
                        }
                    }
                    .info {
                        background: rgba(172, 143, 255, 0.08);
                        padding: 15px 20px;
                        @media screen and (max-width: 800px) {
                            padding: 8px 12px;
                        }
                        .title {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 14px;
                            color: rgba(255, 255, 255, 0.5);
                            margin-bottom: 12px;
                            @media screen and (max-width: 800px) {
                                font-size: 12px;
                            }
                        }
                        .subtitle {
                            display: flex;
                            align-items: center;
                            height: 42px;
                            .text {
                                margin-right: 4px;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 16px;
                                color: #ffffff;
                                @media screen and (max-width: 800px) {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
