// modal start
.ant-modal {
    overflow: hidden;
    border-radius: 10px;
    .ant-modal-content,
    .ant-modal-header {
        background: #23262f;
        color: #fff;
    }
    .ant-modal-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        color: #ffffff;
        text-align: center;
    }
    .ant-modal-header {
        border-bottom: none;
        padding: 34px 24px 20px 24px;
        line-height: 1;
    }
    .ant-input-clear-icon {
        color: #fff;
    }
    .ant-modal-close {
        top: 10px;
        right: 8px;
        .ant-modal-close-x {
            .anticon {
                color: #fff;
                opacity: 0.6;
                font-size: 26px;
            }
        }
        &:hover {
            .ant-modal-close-x {
                .anticon {
                    opacity: 0.9;
                }
            }
        }
    }
}
.custom-modal-1 {
    .ant-modal-body {
        padding: 0 93px 60px 93px;
        height: 286px;
        overflow-y: auto;
        width: 98%;
        margin: auto;
    }
    .ant-modal-footer {
        border: none;
        text-align: center;
        padding-bottom: 30px;
        padding-top: 10px;
        .primary-btn {
            margin: auto;
            width: 220px;
            height: 50px;
        }
    }
}
.select-license-spinning {
    .ant-spin-spinning {
        position: absolute !important;
    }
}
.select-license-nft-modal {
    .ant-modal {
        width: 700px !important;
        height: 474px !important;
        overflow: hidden;
        border-radius: 10px;
        .ant-modal-body {
            padding-top: 10px;
        }
    }
    .form-item-search {
        width: 100%;
        // height: 40px;
        // background: rgba(0, 0, 0, 0.3);
        // border: 1px solid rgba(172, 143, 255, 0.2);
        // border-radius: 10px;
        .ant-input-affix-wrapper {
            background-color: rgba(0, 0, 0, 0.3);
            border: 1px solid rgba(172, 143, 255, 0.2);
            border-radius: 10px;
            .ant-input {
                background: none;
            }
        }
        .ant-input-affix-wrapper-sm {
            padding-left: 10px;
        }
        .ant-input-prefix {
            margin-right: 10px;
        }
    }
    .form-item-license-list {
        margin: auto;
        .list {
            width: 100%;
            margin: auto;
            max-height: 260px;
            min-height: 200px;
            overflow-y: auto;
            .list-wrapp {
                column-count: 2;
                width: 620px;
                margin: auto;
                .group {
                    margin-bottom: 20px;
                    .item {
                        width: 100%;
                        height: 40px;
                        border-radius: 6px;
                        margin-bottom: 10px;
                        cursor: pointer;
                        display: flex;
                        &:hover {
                            background: rgba(172, 143, 255, 0.08);
                        }
                        .item-left {
                            .img-wrap {
                                border-radius: 4px;
                                overflow: hidden;
                            }
                            .img {
                                width: 40px;
                                height: 40px;
                                object-fit: contain;
                            }
                            .default-img {
                                width: 40px;
                                height: 40px;
                                font-size: 40px;
                                opacity: 0.3;
                            }
                        }
                        .item-right {
                            padding: 6px 10px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            .row1 {
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 12px;
                                color: rgba(255, 255, 255, 0.5);
                            }
                            .row2 {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 16px;
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
            .no-data {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 24px;
                color: #ffffff;
                .title {
                    margin-bottom: 60px;
                    margin-top: 10px;
                }
                button {
                    width: 220px;
                    height: 50px;
                    border: 1px solid rgba(172, 143, 255, 0.3);
                    border-radius: 8px;
                    background: none;
                    color: #fff;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 16px;
                    text-align: center;
                    color: #ffffff;
                }
            }
        }
    }
}
// modal end

// form start
.custom-form-1 {
    .ant-input-affix-wrapper {
        background-color: rgba(0, 0, 0, 0.3);
        border: 1px solid rgba(172, 143, 255, 0.2);
        border-radius: 10px;
        padding-left: 6px;
        .ant-input {
            background: none;
        }
    }
    .ant-input-prefix {
        width: 30px;
        height: 30px;
        background: rgba(54, 54, 54, 0.5);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 1px;
        margin-right: 8px;
        .micon {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .ant-space {
        width: 100%;
        .ant-space-item {
            &:first-child {
                width: 280px;
                .ant-input-affix-wrapper {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            &:last-child {
                width: 240px;
                .ant-input {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }

    .ant-form-item {
        margin-bottom: 10px;
    }
    .ant-input {
        line-height: 2;
        border-radius: 10px;
    }
    .tips {
        margin: auto;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 20px;
        width: 460px;
    }
    .list-title {
        display: flex;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.5);
        line-height: 36px;
        .left {
            padding-left: 40px;
            margin-right: 204px;
        }
    }

    [name='icon57'] {
        width: 40px;
        height: 40px;
        font-size: 40px;
        cursor: pointer;
        circle {
            opacity: 0.2;
        }
        g {
            opacity: 0.7;
        }
        &:hover {
            circle {
                opacity: 1;
            }
            g {
                opacity: 1;
            }
        }
    }
}
.add-levels-form {
    .ant-form-item {
        margin-bottom: 0;
    }
    .ant-space {
        width: 100%;
        .ant-space-item {
            &:first-child {
                width: 290px;
            }
            &:last-child {
                float: 1;
            }
            .form-value-item {
                .ant-form-item-control-input-content {
                    display: flex;
                    .form-of-item {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 16px;
                        color: #ffffff;
                        margin-left: 10px;
                        margin-right: 10px;
                        line-height: 42px;
                    }
                    .form-start-item {
                        .ant-input {
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }
}
// form end
.creation-progress-modal {
    .ant-modal-body {
        padding: 24px 52px;
    }
    .footer {
        text-align: center;
    }
    .creation-progress-container {
        .wrap {
            .progress {
                margin-bottom: 40px;
                .title {
                    display: flex;
                    margin-bottom: 20px;
                    .left {
                        width: 24px;
                        height: 24px;
                        margin-right: 22px;
                        position: relative;
                        .label {
                            font-weight: 600;
                            font-size: 14px;
                            text-align: center;
                            color: #ffffff;
                            position: absolute;
                            z-index: 10;
                            width: 100%;
                            height: 100%;
                            line-height: 24px;
                            top: 0px;
                        }
                        .circle,
                        .semicircle,
                        .completed {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            font-size: 24px;
                            > .anticon {
                                position: absolute;
                            }
                        }
                        .semicircle {
                            animation: rotate 1.6s linear infinite;
                        }
                        @keyframes rotate {
                            0% {
                                transform: rotate(0deg);
                            }

                            50% {
                                transform: rotate(180deg);
                            }

                            100% {
                                transform: rotate(360deg);
                            }
                        }
                    }
                    .right {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 20px;
                        color: #ffffff;
                    }
                }
                .status-content {
                    display: flex;
                    .left {
                        width: 24px;
                        height: 24px;
                        margin-right: 22px;
                    }
                    .right {
                        .status-completed {
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 20px;
                            margin-bottom: 20px;
                        }
                        .status-progress {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 16px;
                            color: rgba(255, 255, 255, 0.7);
                            &.progress1 {
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 14px;
                                color: #06f201;
                                margin-top: 20px;
                                height: 18px;
                            }
                            &.progress2 {
                                color: rgba(255, 255, 255, 0.7);
                            }
                        }
                    }
                }
            }
        }
    }
}
